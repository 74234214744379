/*!--------------------------------------------------------------
# main.scss
#
# Sass file for a template.
# Author: SaltTechno
#
#
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# [TABLE OF CONTENTS]
#
# 1. VARIABLES & MIXINS
# 2. TOP NAVBAR
# 3. COVER
# 4. PAGE TITLE
# 5. CTA
# 6. PARTICLES
# 7. CARDS
# 8. SIDEBAR
# 9. FOOTER
# 10. BOXED & WIDE
# 11. MAP
# 12. TIMELINE
# 13. SECTIONS
# 14. ACCORDIONS
# 15. CAROUSEL
# 16. OWL CAROUSEL
# 17. FEATURES
# 18. BLOG
# 19. MISCELLANEOUS
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# 1. VARIABLES & MIXINS
--------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css?family=Roboto|Montserrat");
body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  background-color: #f2f2f2;
  color: #494949;
  text-rendering: optimizeLegibility !important;
  letter-spacing: 0.5px;
  overflow-x: hidden;
}

::-moz-selection {
  background-color: #292929;
  color: #ffffff;
}

::selection {
  background-color: #292929;
  color: #ffffff;
}

a {
  outline: none !important;
  text-decoration: none !important;
  color: #494949;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.white-text {
  color: #ffffff !important;
}

.primary-text {
  color: #002e5b !important;
}

.gray-text {
  color: #636c72 !important;
}

.light-black-text {
  color: #494949 !important;
}

.light-white-text {
  color: #dcdcdc !important;
}

/*--------------------------------------------------------------
# MIXIN TO CORRECT NAVBAR WITH OUR COLOR PALETTE
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# BACKGROUND COLOR PALLETES
--------------------------------------------------------------*/
.bg-primary {
  background-color: #002e5b;
  border-color: #002e5b;
  color: #ffffff !important;
}

.bg-primary h1, .bg-primary h2, .bg-primary h3, .bg-primary h4, .bg-primary h5, .bg-primary h6 {
  color: #ffffff;
}

.bg-primary .nav .open > a, .bg-primary .nav .open > a:focus, .bg-primary .nav .open > a:hover {
  background-color: #002142;
  color: #ffffff !important;
}

.bg-primary .nav > li > a:focus, .bg-primary .nav > li > a:hover {
  background-color: #002142;
  color: #ffffff !important;
}

.bg-primary .navbar-nav > li > a {
  color: #ffffff;
}

.bg-primary.small-nav:hover .child-nav {
  background-color: #002e5b;
}

.bg-primary.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #002e5b;
}

.bg-primary-300 {
  background-color: #004181;
  border-color: #004181;
  color: #ffffff !important;
}

.bg-primary-300 h1, .bg-primary-300 h2, .bg-primary-300 h3, .bg-primary-300 h4, .bg-primary-300 h5, .bg-primary-300 h6 {
  color: #ffffff;
}

.bg-primary-300 .nav .open > a, .bg-primary-300 .nav .open > a:focus, .bg-primary-300 .nav .open > a:hover {
  background-color: #003468;
  color: #ffffff !important;
}

.bg-primary-300 .nav > li > a:focus, .bg-primary-300 .nav > li > a:hover {
  background-color: #003468;
  color: #ffffff !important;
}

.bg-primary-300 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-primary-300.small-nav:hover .child-nav {
  background-color: #004181;
}

.bg-primary-300.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #004181;
}

.bg-primary-100 {
  background-color: #0055a8;
  border-color: #0055a8;
  color: #ffffff !important;
}

.bg-primary-100 h1, .bg-primary-100 h2, .bg-primary-100 h3, .bg-primary-100 h4, .bg-primary-100 h5, .bg-primary-100 h6 {
  color: #ffffff;
}

.bg-primary-100 .nav .open > a, .bg-primary-100 .nav .open > a:focus, .bg-primary-100 .nav .open > a:hover {
  background-color: #00488e;
  color: #ffffff !important;
}

.bg-primary-100 .nav > li > a:focus, .bg-primary-100 .nav > li > a:hover {
  background-color: #00488e;
  color: #ffffff !important;
}

.bg-primary-100 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-primary-100.small-nav:hover .child-nav {
  background-color: #0055a8;
}

.bg-primary-100.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #0055a8;
}

.bg-primary-500 {
  background-color: #002142;
  border-color: #002142;
  color: #ffffff !important;
}

.bg-primary-500 h1, .bg-primary-500 h2, .bg-primary-500 h3, .bg-primary-500 h4, .bg-primary-500 h5, .bg-primary-500 h6 {
  color: #ffffff;
}

.bg-primary-500 .nav .open > a, .bg-primary-500 .nav .open > a:focus, .bg-primary-500 .nav .open > a:hover {
  background-color: #001428;
  color: #ffffff !important;
}

.bg-primary-500 .nav > li > a:focus, .bg-primary-500 .nav > li > a:hover {
  background-color: #001428;
  color: #ffffff !important;
}

.bg-primary-500 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-primary-500.small-nav:hover .child-nav {
  background-color: #002142;
}

.bg-primary-500.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #002142;
}

.bg-primary-600 {
  background-color: #001428;
  border-color: #001428;
  color: #ffffff !important;
}

.bg-primary-600 h1, .bg-primary-600 h2, .bg-primary-600 h3, .bg-primary-600 h4, .bg-primary-600 h5, .bg-primary-600 h6 {
  color: #ffffff;
}

.bg-primary-600 .nav .open > a, .bg-primary-600 .nav .open > a:focus, .bg-primary-600 .nav .open > a:hover {
  background-color: #00070f;
  color: #ffffff !important;
}

.bg-primary-600 .nav > li > a:focus, .bg-primary-600 .nav > li > a:hover {
  background-color: #00070f;
  color: #ffffff !important;
}

.bg-primary-600 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-primary-600.small-nav:hover .child-nav {
  background-color: #001428;
}

.bg-primary-600.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #001428;
}

.bg-primary-700 {
  background-color: #00070f;
  border-color: #00070f;
  color: #ffffff !important;
}

.bg-primary-700 h1, .bg-primary-700 h2, .bg-primary-700 h3, .bg-primary-700 h4, .bg-primary-700 h5, .bg-primary-700 h6 {
  color: #ffffff;
}

.bg-primary-700 .nav .open > a, .bg-primary-700 .nav .open > a:focus, .bg-primary-700 .nav .open > a:hover {
  background-color: black;
  color: #ffffff !important;
}

.bg-primary-700 .nav > li > a:focus, .bg-primary-700 .nav > li > a:hover {
  background-color: black;
  color: #ffffff !important;
}

.bg-primary-700 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-primary-700.small-nav:hover .child-nav {
  background-color: #00070f;
}

.bg-primary-700.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #00070f;
}

.bg-danger {
  background-color: #F44336;
  border-color: #F44336;
  color: #ffffff !important;
}

.bg-danger h1, .bg-danger h2, .bg-danger h3, .bg-danger h4, .bg-danger h5, .bg-danger h6 {
  color: #ffffff;
}

.bg-danger .nav .open > a, .bg-danger .nav .open > a:focus, .bg-danger .nav .open > a:hover {
  background-color: #f32c1e;
  color: #ffffff !important;
}

.bg-danger .nav > li > a:focus, .bg-danger .nav > li > a:hover {
  background-color: #f32c1e;
  color: #ffffff !important;
}

.bg-danger .navbar-nav > li > a {
  color: #ffffff;
}

.bg-danger.small-nav:hover .child-nav {
  background-color: #F44336;
}

.bg-danger.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #F44336;
}

.bg-danger-300 {
  background-color: #f6655a;
  border-color: #f6655a;
  color: #ffffff !important;
}

.bg-danger-300 h1, .bg-danger-300 h2, .bg-danger-300 h3, .bg-danger-300 h4, .bg-danger-300 h5, .bg-danger-300 h6 {
  color: #ffffff;
}

.bg-danger-300 .nav .open > a, .bg-danger-300 .nav .open > a:focus, .bg-danger-300 .nav .open > a:hover {
  background-color: #f54e42;
  color: #ffffff !important;
}

.bg-danger-300 .nav > li > a:focus, .bg-danger-300 .nav > li > a:hover {
  background-color: #f54e42;
  color: #ffffff !important;
}

.bg-danger-300 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-danger-300.small-nav:hover .child-nav {
  background-color: #f6655a;
}

.bg-danger-300.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #f6655a;
}

.bg-danger-100 {
  background-color: #f8877f;
  border-color: #f8877f;
  color: #ffffff !important;
}

.bg-danger-100 h1, .bg-danger-100 h2, .bg-danger-100 h3, .bg-danger-100 h4, .bg-danger-100 h5, .bg-danger-100 h6 {
  color: #ffffff;
}

.bg-danger-100 .nav .open > a, .bg-danger-100 .nav .open > a:focus, .bg-danger-100 .nav .open > a:hover {
  background-color: #f77066;
  color: #ffffff !important;
}

.bg-danger-100 .nav > li > a:focus, .bg-danger-100 .nav > li > a:hover {
  background-color: #f77066;
  color: #ffffff !important;
}

.bg-danger-100 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-danger-100.small-nav:hover .child-nav {
  background-color: #f8877f;
}

.bg-danger-100.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #f8877f;
}

.bg-danger-500 {
  background-color: #f32c1e;
  border-color: #f32c1e;
  color: #ffffff !important;
}

.bg-danger-500 h1, .bg-danger-500 h2, .bg-danger-500 h3, .bg-danger-500 h4, .bg-danger-500 h5, .bg-danger-500 h6 {
  color: #ffffff;
}

.bg-danger-500 .nav .open > a, .bg-danger-500 .nav .open > a:focus, .bg-danger-500 .nav .open > a:hover {
  background-color: #ea1c0d;
  color: #ffffff !important;
}

.bg-danger-500 .nav > li > a:focus, .bg-danger-500 .nav > li > a:hover {
  background-color: #ea1c0d;
  color: #ffffff !important;
}

.bg-danger-500 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-danger-500.small-nav:hover .child-nav {
  background-color: #f32c1e;
}

.bg-danger-500.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #f32c1e;
}

.bg-danger-600 {
  background-color: #ea1c0d;
  border-color: #ea1c0d;
  color: #ffffff !important;
}

.bg-danger-600 h1, .bg-danger-600 h2, .bg-danger-600 h3, .bg-danger-600 h4, .bg-danger-600 h5, .bg-danger-600 h6 {
  color: #ffffff;
}

.bg-danger-600 .nav .open > a, .bg-danger-600 .nav .open > a:focus, .bg-danger-600 .nav .open > a:hover {
  background-color: #d2190b;
  color: #ffffff !important;
}

.bg-danger-600 .nav > li > a:focus, .bg-danger-600 .nav > li > a:hover {
  background-color: #d2190b;
  color: #ffffff !important;
}

.bg-danger-600 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-danger-600.small-nav:hover .child-nav {
  background-color: #ea1c0d;
}

.bg-danger-600.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #ea1c0d;
}

.bg-danger-700 {
  background-color: #d2190b;
  border-color: #d2190b;
  color: #ffffff !important;
}

.bg-danger-700 h1, .bg-danger-700 h2, .bg-danger-700 h3, .bg-danger-700 h4, .bg-danger-700 h5, .bg-danger-700 h6 {
  color: #ffffff;
}

.bg-danger-700 .nav .open > a, .bg-danger-700 .nav .open > a:focus, .bg-danger-700 .nav .open > a:hover {
  background-color: #ba160a;
  color: #ffffff !important;
}

.bg-danger-700 .nav > li > a:focus, .bg-danger-700 .nav > li > a:hover {
  background-color: #ba160a;
  color: #ffffff !important;
}

.bg-danger-700 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-danger-700.small-nav:hover .child-nav {
  background-color: #d2190b;
}

.bg-danger-700.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #d2190b;
}

.bg-success {
  background-color: #4CAF50;
  border-color: #4CAF50;
  color: #ffffff !important;
}

.bg-success h1, .bg-success h2, .bg-success h3, .bg-success h4, .bg-success h5, .bg-success h6 {
  color: #ffffff;
}

.bg-success .nav .open > a, .bg-success .nav .open > a:focus, .bg-success .nav .open > a:hover {
  background-color: #449d48;
  color: #ffffff !important;
}

.bg-success .nav > li > a:focus, .bg-success .nav > li > a:hover {
  background-color: #449d48;
  color: #ffffff !important;
}

.bg-success .navbar-nav > li > a {
  color: #ffffff;
}

.bg-success.small-nav:hover .child-nav {
  background-color: #4CAF50;
}

.bg-success.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #4CAF50;
}

.bg-success-300 {
  background-color: #65bc69;
  border-color: #65bc69;
  color: #ffffff !important;
}

.bg-success-300 h1, .bg-success-300 h2, .bg-success-300 h3, .bg-success-300 h4, .bg-success-300 h5, .bg-success-300 h6 {
  color: #ffffff;
}

.bg-success-300 .nav .open > a, .bg-success-300 .nav .open > a:focus, .bg-success-300 .nav .open > a:hover {
  background-color: #53b457;
  color: #ffffff !important;
}

.bg-success-300 .nav > li > a:focus, .bg-success-300 .nav > li > a:hover {
  background-color: #53b457;
  color: #ffffff !important;
}

.bg-success-300 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-success-300.small-nav:hover .child-nav {
  background-color: #65bc69;
}

.bg-success-300.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #65bc69;
}

.bg-success-100 {
  background-color: #80c883;
  border-color: #80c883;
  color: #ffffff !important;
}

.bg-success-100 h1, .bg-success-100 h2, .bg-success-100 h3, .bg-success-100 h4, .bg-success-100 h5, .bg-success-100 h6 {
  color: #ffffff;
}

.bg-success-100 .nav .open > a, .bg-success-100 .nav .open > a:focus, .bg-success-100 .nav .open > a:hover {
  background-color: #6ec071;
  color: #ffffff !important;
}

.bg-success-100 .nav > li > a:focus, .bg-success-100 .nav > li > a:hover {
  background-color: #6ec071;
  color: #ffffff !important;
}

.bg-success-100 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-success-100.small-nav:hover .child-nav {
  background-color: #80c883;
}

.bg-success-100.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #80c883;
}

.bg-success-500 {
  background-color: #449d48;
  border-color: #449d48;
  color: #ffffff !important;
}

.bg-success-500 h1, .bg-success-500 h2, .bg-success-500 h3, .bg-success-500 h4, .bg-success-500 h5, .bg-success-500 h6 {
  color: #ffffff;
}

.bg-success-500 .nav .open > a, .bg-success-500 .nav .open > a:focus, .bg-success-500 .nav .open > a:hover {
  background-color: #3d8b40;
  color: #ffffff !important;
}

.bg-success-500 .nav > li > a:focus, .bg-success-500 .nav > li > a:hover {
  background-color: #3d8b40;
  color: #ffffff !important;
}

.bg-success-500 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-success-500.small-nav:hover .child-nav {
  background-color: #449d48;
}

.bg-success-500.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #449d48;
}

.bg-success-600 {
  background-color: #3d8b40;
  border-color: #3d8b40;
  color: #ffffff !important;
}

.bg-success-600 h1, .bg-success-600 h2, .bg-success-600 h3, .bg-success-600 h4, .bg-success-600 h5, .bg-success-600 h6 {
  color: #ffffff;
}

.bg-success-600 .nav .open > a, .bg-success-600 .nav .open > a:focus, .bg-success-600 .nav .open > a:hover {
  background-color: #357a38;
  color: #ffffff !important;
}

.bg-success-600 .nav > li > a:focus, .bg-success-600 .nav > li > a:hover {
  background-color: #357a38;
  color: #ffffff !important;
}

.bg-success-600 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-success-600.small-nav:hover .child-nav {
  background-color: #3d8b40;
}

.bg-success-600.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #3d8b40;
}

.bg-success-700 {
  background-color: #357a38;
  border-color: #357a38;
  color: #ffffff !important;
}

.bg-success-700 h1, .bg-success-700 h2, .bg-success-700 h3, .bg-success-700 h4, .bg-success-700 h5, .bg-success-700 h6 {
  color: #ffffff;
}

.bg-success-700 .nav .open > a, .bg-success-700 .nav .open > a:focus, .bg-success-700 .nav .open > a:hover {
  background-color: #2d682f;
  color: #ffffff !important;
}

.bg-success-700 .nav > li > a:focus, .bg-success-700 .nav > li > a:hover {
  background-color: #2d682f;
  color: #ffffff !important;
}

.bg-success-700 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-success-700.small-nav:hover .child-nav {
  background-color: #357a38;
}

.bg-success-700.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #357a38;
}

.bg-warning {
  background-color: #FF9800;
  border-color: #FF9800;
  color: #ffffff !important;
}

.bg-warning h1, .bg-warning h2, .bg-warning h3, .bg-warning h4, .bg-warning h5, .bg-warning h6 {
  color: #ffffff;
}

.bg-warning .nav .open > a, .bg-warning .nav .open > a:focus, .bg-warning .nav .open > a:hover {
  background-color: #e68900;
  color: #ffffff !important;
}

.bg-warning .nav > li > a:focus, .bg-warning .nav > li > a:hover {
  background-color: #e68900;
  color: #ffffff !important;
}

.bg-warning .navbar-nav > li > a {
  color: #ffffff;
}

.bg-warning.small-nav:hover .child-nav {
  background-color: #FF9800;
}

.bg-warning.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #FF9800;
}

.bg-warning-300 {
  background-color: #ffa726;
  border-color: #ffa726;
  color: #ffffff !important;
}

.bg-warning-300 h1, .bg-warning-300 h2, .bg-warning-300 h3, .bg-warning-300 h4, .bg-warning-300 h5, .bg-warning-300 h6 {
  color: #ffffff;
}

.bg-warning-300 .nav .open > a, .bg-warning-300 .nav .open > a:focus, .bg-warning-300 .nav .open > a:hover {
  background-color: #ff9d0d;
  color: #ffffff !important;
}

.bg-warning-300 .nav > li > a:focus, .bg-warning-300 .nav > li > a:hover {
  background-color: #ff9d0d;
  color: #ffffff !important;
}

.bg-warning-300 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-warning-300.small-nav:hover .child-nav {
  background-color: #ffa726;
}

.bg-warning-300.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #ffa726;
}

.bg-warning-100 {
  background-color: #ffb74d;
  border-color: #ffb74d;
  color: #ffffff !important;
}

.bg-warning-100 h1, .bg-warning-100 h2, .bg-warning-100 h3, .bg-warning-100 h4, .bg-warning-100 h5, .bg-warning-100 h6 {
  color: #ffffff;
}

.bg-warning-100 .nav .open > a, .bg-warning-100 .nav .open > a:focus, .bg-warning-100 .nav .open > a:hover {
  background-color: #ffad33;
  color: #ffffff !important;
}

.bg-warning-100 .nav > li > a:focus, .bg-warning-100 .nav > li > a:hover {
  background-color: #ffad33;
  color: #ffffff !important;
}

.bg-warning-100 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-warning-100.small-nav:hover .child-nav {
  background-color: #ffb74d;
}

.bg-warning-100.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #ffb74d;
}

.bg-warning-500 {
  background-color: #e68900;
  border-color: #e68900;
  color: #ffffff !important;
}

.bg-warning-500 h1, .bg-warning-500 h2, .bg-warning-500 h3, .bg-warning-500 h4, .bg-warning-500 h5, .bg-warning-500 h6 {
  color: #ffffff;
}

.bg-warning-500 .nav .open > a, .bg-warning-500 .nav .open > a:focus, .bg-warning-500 .nav .open > a:hover {
  background-color: #cc7a00;
  color: #ffffff !important;
}

.bg-warning-500 .nav > li > a:focus, .bg-warning-500 .nav > li > a:hover {
  background-color: #cc7a00;
  color: #ffffff !important;
}

.bg-warning-500 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-warning-500.small-nav:hover .child-nav {
  background-color: #e68900;
}

.bg-warning-500.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #e68900;
}

.bg-warning-600 {
  background-color: #cc7a00;
  border-color: #cc7a00;
  color: #ffffff !important;
}

.bg-warning-600 h1, .bg-warning-600 h2, .bg-warning-600 h3, .bg-warning-600 h4, .bg-warning-600 h5, .bg-warning-600 h6 {
  color: #ffffff;
}

.bg-warning-600 .nav .open > a, .bg-warning-600 .nav .open > a:focus, .bg-warning-600 .nav .open > a:hover {
  background-color: #b36a00;
  color: #ffffff !important;
}

.bg-warning-600 .nav > li > a:focus, .bg-warning-600 .nav > li > a:hover {
  background-color: #b36a00;
  color: #ffffff !important;
}

.bg-warning-600 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-warning-600.small-nav:hover .child-nav {
  background-color: #cc7a00;
}

.bg-warning-600.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #cc7a00;
}

.bg-warning-700 {
  background-color: #b36a00;
  border-color: #b36a00;
  color: #ffffff !important;
}

.bg-warning-700 h1, .bg-warning-700 h2, .bg-warning-700 h3, .bg-warning-700 h4, .bg-warning-700 h5, .bg-warning-700 h6 {
  color: #ffffff;
}

.bg-warning-700 .nav .open > a, .bg-warning-700 .nav .open > a:focus, .bg-warning-700 .nav .open > a:hover {
  background-color: #995b00;
  color: #ffffff !important;
}

.bg-warning-700 .nav > li > a:focus, .bg-warning-700 .nav > li > a:hover {
  background-color: #995b00;
  color: #ffffff !important;
}

.bg-warning-700 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-warning-700.small-nav:hover .child-nav {
  background-color: #b36a00;
}

.bg-warning-700.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #b36a00;
}

.bg-info {
  background-color: #607D8B;
  border-color: #607D8B;
  color: #ffffff !important;
}

.bg-info h1, .bg-info h2, .bg-info h3, .bg-info h4, .bg-info h5, .bg-info h6 {
  color: #ffffff;
}

.bg-info .nav .open > a, .bg-info .nav .open > a:focus, .bg-info .nav .open > a:hover {
  background-color: #566f7c;
  color: #ffffff !important;
}

.bg-info .nav > li > a:focus, .bg-info .nav > li > a:hover {
  background-color: #566f7c;
  color: #ffffff !important;
}

.bg-info .navbar-nav > li > a {
  color: #ffffff;
}

.bg-info.small-nav:hover .child-nav {
  background-color: #607D8B;
}

.bg-info.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #607D8B;
}

.bg-info-300 {
  background-color: #73909e;
  border-color: #73909e;
  color: #ffffff !important;
}

.bg-info-300 h1, .bg-info-300 h2, .bg-info-300 h3, .bg-info-300 h4, .bg-info-300 h5, .bg-info-300 h6 {
  color: #ffffff;
}

.bg-info-300 .nav .open > a, .bg-info-300 .nav .open > a:focus, .bg-info-300 .nav .open > a:hover {
  background-color: #658493;
  color: #ffffff !important;
}

.bg-info-300 .nav > li > a:focus, .bg-info-300 .nav > li > a:hover {
  background-color: #658493;
  color: #ffffff !important;
}

.bg-info-300 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-info-300.small-nav:hover .child-nav {
  background-color: #73909e;
}

.bg-info-300.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #73909e;
}

.bg-info-100 {
  background-color: #8aa2ae;
  border-color: #8aa2ae;
  color: #ffffff !important;
}

.bg-info-100 h1, .bg-info-100 h2, .bg-info-100 h3, .bg-info-100 h4, .bg-info-100 h5, .bg-info-100 h6 {
  color: #ffffff;
}

.bg-info-100 .nav .open > a, .bg-info-100 .nav .open > a:focus, .bg-info-100 .nav .open > a:hover {
  background-color: #7b96a3;
  color: #ffffff !important;
}

.bg-info-100 .nav > li > a:focus, .bg-info-100 .nav > li > a:hover {
  background-color: #7b96a3;
  color: #ffffff !important;
}

.bg-info-100 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-info-100.small-nav:hover .child-nav {
  background-color: #8aa2ae;
}

.bg-info-100.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #8aa2ae;
}

.bg-info-500 {
  background-color: #566f7c;
  border-color: #566f7c;
  color: #ffffff !important;
}

.bg-info-500 h1, .bg-info-500 h2, .bg-info-500 h3, .bg-info-500 h4, .bg-info-500 h5, .bg-info-500 h6 {
  color: #ffffff;
}

.bg-info-500 .nav .open > a, .bg-info-500 .nav .open > a:focus, .bg-info-500 .nav .open > a:hover {
  background-color: #4b626d;
  color: #ffffff !important;
}

.bg-info-500 .nav > li > a:focus, .bg-info-500 .nav > li > a:hover {
  background-color: #4b626d;
  color: #ffffff !important;
}

.bg-info-500 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-info-500.small-nav:hover .child-nav {
  background-color: #566f7c;
}

.bg-info-500.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #566f7c;
}

.bg-info-600 {
  background-color: #4b626d;
  border-color: #4b626d;
  color: #ffffff !important;
}

.bg-info-600 h1, .bg-info-600 h2, .bg-info-600 h3, .bg-info-600 h4, .bg-info-600 h5, .bg-info-600 h6 {
  color: #ffffff;
}

.bg-info-600 .nav .open > a, .bg-info-600 .nav .open > a:focus, .bg-info-600 .nav .open > a:hover {
  background-color: #41545e;
  color: #ffffff !important;
}

.bg-info-600 .nav > li > a:focus, .bg-info-600 .nav > li > a:hover {
  background-color: #41545e;
  color: #ffffff !important;
}

.bg-info-600 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-info-600.small-nav:hover .child-nav {
  background-color: #4b626d;
}

.bg-info-600.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #4b626d;
}

.bg-info-700 {
  background-color: #41545e;
  border-color: #41545e;
  color: #ffffff !important;
}

.bg-info-700 h1, .bg-info-700 h2, .bg-info-700 h3, .bg-info-700 h4, .bg-info-700 h5, .bg-info-700 h6 {
  color: #ffffff;
}

.bg-info-700 .nav .open > a, .bg-info-700 .nav .open > a:focus, .bg-info-700 .nav .open > a:hover {
  background-color: #36474f;
  color: #ffffff !important;
}

.bg-info-700 .nav > li > a:focus, .bg-info-700 .nav > li > a:hover {
  background-color: #36474f;
  color: #ffffff !important;
}

.bg-info-700 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-info-700.small-nav:hover .child-nav {
  background-color: #41545e;
}

.bg-info-700.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #41545e;
}

.bg-black {
  background-color: #292929;
  border-color: #292929;
  color: #ffffff !important;
}

.bg-black h1, .bg-black h2, .bg-black h3, .bg-black h4, .bg-black h5, .bg-black h6 {
  color: #ffffff;
}

.bg-black .nav .open > a, .bg-black .nav .open > a:focus, .bg-black .nav .open > a:hover {
  background-color: #1c1c1c;
  color: #ffffff !important;
}

.bg-black .nav > li > a:focus, .bg-black .nav > li > a:hover {
  background-color: #1c1c1c;
  color: #ffffff !important;
}

.bg-black .navbar-nav > li > a {
  color: #ffffff;
}

.bg-black.small-nav:hover .child-nav {
  background-color: #292929;
}

.bg-black.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #292929;
}

.bg-black-300 {
  background-color: #3c3c3c;
  border-color: #3c3c3c;
  color: #ffffff !important;
}

.bg-black-300 h1, .bg-black-300 h2, .bg-black-300 h3, .bg-black-300 h4, .bg-black-300 h5, .bg-black-300 h6 {
  color: #ffffff;
}

.bg-black-300 .nav .open > a, .bg-black-300 .nav .open > a:focus, .bg-black-300 .nav .open > a:hover {
  background-color: #2f2f2f;
  color: #ffffff !important;
}

.bg-black-300 .nav > li > a:focus, .bg-black-300 .nav > li > a:hover {
  background-color: #2f2f2f;
  color: #ffffff !important;
}

.bg-black-300 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-black-300.small-nav:hover .child-nav {
  background-color: #3c3c3c;
}

.bg-black-300.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #3c3c3c;
}

.bg-black-100 {
  background-color: #4f4f4f;
  border-color: #4f4f4f;
  color: #ffffff !important;
}

.bg-black-100 h1, .bg-black-100 h2, .bg-black-100 h3, .bg-black-100 h4, .bg-black-100 h5, .bg-black-100 h6 {
  color: #ffffff;
}

.bg-black-100 .nav .open > a, .bg-black-100 .nav .open > a:focus, .bg-black-100 .nav .open > a:hover {
  background-color: #434343;
  color: #ffffff !important;
}

.bg-black-100 .nav > li > a:focus, .bg-black-100 .nav > li > a:hover {
  background-color: #434343;
  color: #ffffff !important;
}

.bg-black-100 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-black-100.small-nav:hover .child-nav {
  background-color: #4f4f4f;
}

.bg-black-100.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #4f4f4f;
}

.bg-black-500 {
  background-color: #1c1c1c;
  border-color: #1c1c1c;
  color: #ffffff !important;
}

.bg-black-500 h1, .bg-black-500 h2, .bg-black-500 h3, .bg-black-500 h4, .bg-black-500 h5, .bg-black-500 h6 {
  color: #ffffff;
}

.bg-black-500 .nav .open > a, .bg-black-500 .nav .open > a:focus, .bg-black-500 .nav .open > a:hover {
  background-color: #101010;
  color: #ffffff !important;
}

.bg-black-500 .nav > li > a:focus, .bg-black-500 .nav > li > a:hover {
  background-color: #101010;
  color: #ffffff !important;
}

.bg-black-500 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-black-500.small-nav:hover .child-nav {
  background-color: #1c1c1c;
}

.bg-black-500.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #1c1c1c;
}

.bg-black-600 {
  background-color: #101010;
  border-color: #101010;
  color: #ffffff !important;
}

.bg-black-600 h1, .bg-black-600 h2, .bg-black-600 h3, .bg-black-600 h4, .bg-black-600 h5, .bg-black-600 h6 {
  color: #ffffff;
}

.bg-black-600 .nav .open > a, .bg-black-600 .nav .open > a:focus, .bg-black-600 .nav .open > a:hover {
  background-color: #030303;
  color: #ffffff !important;
}

.bg-black-600 .nav > li > a:focus, .bg-black-600 .nav > li > a:hover {
  background-color: #030303;
  color: #ffffff !important;
}

.bg-black-600 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-black-600.small-nav:hover .child-nav {
  background-color: #101010;
}

.bg-black-600.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #101010;
}

.bg-black-700 {
  background-color: #030303;
  border-color: #030303;
  color: #ffffff !important;
}

.bg-black-700 h1, .bg-black-700 h2, .bg-black-700 h3, .bg-black-700 h4, .bg-black-700 h5, .bg-black-700 h6 {
  color: #ffffff;
}

.bg-black-700 .nav .open > a, .bg-black-700 .nav .open > a:focus, .bg-black-700 .nav .open > a:hover {
  background-color: black;
  color: #ffffff !important;
}

.bg-black-700 .nav > li > a:focus, .bg-black-700 .nav > li > a:hover {
  background-color: black;
  color: #ffffff !important;
}

.bg-black-700 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-black-700.small-nav:hover .child-nav {
  background-color: #030303;
}

.bg-black-700.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #030303;
}

.bg-light-black {
  background-color: #494949;
  border-color: #494949;
  color: #ffffff !important;
}

.bg-light-black h1, .bg-light-black h2, .bg-light-black h3, .bg-light-black h4, .bg-light-black h5, .bg-light-black h6 {
  color: #ffffff;
}

.bg-light-black .nav .open > a, .bg-light-black .nav .open > a:focus, .bg-light-black .nav .open > a:hover {
  background-color: #3c3c3c;
  color: #ffffff !important;
}

.bg-light-black .nav > li > a:focus, .bg-light-black .nav > li > a:hover {
  background-color: #3c3c3c;
  color: #ffffff !important;
}

.bg-light-black .navbar-nav > li > a {
  color: #ffffff;
}

.bg-light-black.small-nav:hover .child-nav {
  background-color: #494949;
}

.bg-light-black.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #494949;
}

.bg-light-black-300 {
  background-color: #5c5c5c;
  border-color: #5c5c5c;
  color: #ffffff !important;
}

.bg-light-black-300 h1, .bg-light-black-300 h2, .bg-light-black-300 h3, .bg-light-black-300 h4, .bg-light-black-300 h5, .bg-light-black-300 h6 {
  color: #ffffff;
}

.bg-light-black-300 .nav .open > a, .bg-light-black-300 .nav .open > a:focus, .bg-light-black-300 .nav .open > a:hover {
  background-color: #4f4f4f;
  color: #ffffff !important;
}

.bg-light-black-300 .nav > li > a:focus, .bg-light-black-300 .nav > li > a:hover {
  background-color: #4f4f4f;
  color: #ffffff !important;
}

.bg-light-black-300 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-light-black-300.small-nav:hover .child-nav {
  background-color: #5c5c5c;
}

.bg-light-black-300.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #5c5c5c;
}

.bg-light-black-100 {
  background-color: #6f6f6f;
  border-color: #6f6f6f;
  color: #ffffff !important;
}

.bg-light-black-100 h1, .bg-light-black-100 h2, .bg-light-black-100 h3, .bg-light-black-100 h4, .bg-light-black-100 h5, .bg-light-black-100 h6 {
  color: #ffffff;
}

.bg-light-black-100 .nav .open > a, .bg-light-black-100 .nav .open > a:focus, .bg-light-black-100 .nav .open > a:hover {
  background-color: #636363;
  color: #ffffff !important;
}

.bg-light-black-100 .nav > li > a:focus, .bg-light-black-100 .nav > li > a:hover {
  background-color: #636363;
  color: #ffffff !important;
}

.bg-light-black-100 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-light-black-100.small-nav:hover .child-nav {
  background-color: #6f6f6f;
}

.bg-light-black-100.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #6f6f6f;
}

.bg-light-black-500 {
  background-color: #3c3c3c;
  border-color: #3c3c3c;
  color: #ffffff !important;
}

.bg-light-black-500 h1, .bg-light-black-500 h2, .bg-light-black-500 h3, .bg-light-black-500 h4, .bg-light-black-500 h5, .bg-light-black-500 h6 {
  color: #ffffff;
}

.bg-light-black-500 .nav .open > a, .bg-light-black-500 .nav .open > a:focus, .bg-light-black-500 .nav .open > a:hover {
  background-color: #303030;
  color: #ffffff !important;
}

.bg-light-black-500 .nav > li > a:focus, .bg-light-black-500 .nav > li > a:hover {
  background-color: #303030;
  color: #ffffff !important;
}

.bg-light-black-500 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-light-black-500.small-nav:hover .child-nav {
  background-color: #3c3c3c;
}

.bg-light-black-500.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #3c3c3c;
}

.bg-light-black-600 {
  background-color: #303030;
  border-color: #303030;
  color: #ffffff !important;
}

.bg-light-black-600 h1, .bg-light-black-600 h2, .bg-light-black-600 h3, .bg-light-black-600 h4, .bg-light-black-600 h5, .bg-light-black-600 h6 {
  color: #ffffff;
}

.bg-light-black-600 .nav .open > a, .bg-light-black-600 .nav .open > a:focus, .bg-light-black-600 .nav .open > a:hover {
  background-color: #232323;
  color: #ffffff !important;
}

.bg-light-black-600 .nav > li > a:focus, .bg-light-black-600 .nav > li > a:hover {
  background-color: #232323;
  color: #ffffff !important;
}

.bg-light-black-600 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-light-black-600.small-nav:hover .child-nav {
  background-color: #303030;
}

.bg-light-black-600.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #303030;
}

.bg-light-black-700 {
  background-color: #232323;
  border-color: #232323;
  color: #ffffff !important;
}

.bg-light-black-700 h1, .bg-light-black-700 h2, .bg-light-black-700 h3, .bg-light-black-700 h4, .bg-light-black-700 h5, .bg-light-black-700 h6 {
  color: #ffffff;
}

.bg-light-black-700 .nav .open > a, .bg-light-black-700 .nav .open > a:focus, .bg-light-black-700 .nav .open > a:hover {
  background-color: #161616;
  color: #ffffff !important;
}

.bg-light-black-700 .nav > li > a:focus, .bg-light-black-700 .nav > li > a:hover {
  background-color: #161616;
  color: #ffffff !important;
}

.bg-light-black-700 .navbar-nav > li > a {
  color: #ffffff;
}

.bg-light-black-700.small-nav:hover .child-nav {
  background-color: #232323;
}

.bg-light-black-700.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #232323;
}

.bg-gray {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  color: #494949 !important;
}

.bg-gray h1, .bg-gray h2, .bg-gray h3, .bg-gray h4, .bg-gray h5, .bg-gray h6 {
  color: #494949;
}

.bg-gray .nav .open > a, .bg-gray .nav .open > a:focus, .bg-gray .nav .open > a:hover {
  background-color: #e5e5e5;
  color: #494949 !important;
}

.bg-gray .nav > li > a:focus, .bg-gray .nav > li > a:hover {
  background-color: #e5e5e5;
  color: #494949 !important;
}

.bg-gray .navbar-nav > li > a {
  color: #494949;
}

.bg-gray.small-nav:hover .child-nav {
  background-color: #f2f2f2;
}

.bg-gray.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #f2f2f2;
}

.bg-gray-300 {
  background-color: white;
  border-color: white;
  color: #494949 !important;
}

.bg-gray-300 h1, .bg-gray-300 h2, .bg-gray-300 h3, .bg-gray-300 h4, .bg-gray-300 h5, .bg-gray-300 h6 {
  color: #494949;
}

.bg-gray-300 .nav .open > a, .bg-gray-300 .nav .open > a:focus, .bg-gray-300 .nav .open > a:hover {
  background-color: #f2f2f2;
  color: #494949 !important;
}

.bg-gray-300 .nav > li > a:focus, .bg-gray-300 .nav > li > a:hover {
  background-color: #f2f2f2;
  color: #494949 !important;
}

.bg-gray-300 .navbar-nav > li > a {
  color: #494949;
}

.bg-gray-300.small-nav:hover .child-nav {
  background-color: white;
}

.bg-gray-300.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: white;
}

.bg-gray-100 {
  background-color: white;
  border-color: white;
  color: #494949 !important;
}

.bg-gray-100 h1, .bg-gray-100 h2, .bg-gray-100 h3, .bg-gray-100 h4, .bg-gray-100 h5, .bg-gray-100 h6 {
  color: #494949;
}

.bg-gray-100 .nav .open > a, .bg-gray-100 .nav .open > a:focus, .bg-gray-100 .nav .open > a:hover {
  background-color: #f2f2f2;
  color: #494949 !important;
}

.bg-gray-100 .nav > li > a:focus, .bg-gray-100 .nav > li > a:hover {
  background-color: #f2f2f2;
  color: #494949 !important;
}

.bg-gray-100 .navbar-nav > li > a {
  color: #494949;
}

.bg-gray-100.small-nav:hover .child-nav {
  background-color: white;
}

.bg-gray-100.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: white;
}

.bg-gray-500 {
  background-color: #e5e5e5;
  border-color: #e5e5e5;
  color: #494949 !important;
}

.bg-gray-500 h1, .bg-gray-500 h2, .bg-gray-500 h3, .bg-gray-500 h4, .bg-gray-500 h5, .bg-gray-500 h6 {
  color: #494949;
}

.bg-gray-500 .nav .open > a, .bg-gray-500 .nav .open > a:focus, .bg-gray-500 .nav .open > a:hover {
  background-color: #d9d9d9;
  color: #494949 !important;
}

.bg-gray-500 .nav > li > a:focus, .bg-gray-500 .nav > li > a:hover {
  background-color: #d9d9d9;
  color: #494949 !important;
}

.bg-gray-500 .navbar-nav > li > a {
  color: #494949;
}

.bg-gray-500.small-nav:hover .child-nav {
  background-color: #e5e5e5;
}

.bg-gray-500.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #e5e5e5;
}

.bg-gray-600 {
  background-color: #d9d9d9;
  border-color: #d9d9d9;
  color: #494949 !important;
}

.bg-gray-600 h1, .bg-gray-600 h2, .bg-gray-600 h3, .bg-gray-600 h4, .bg-gray-600 h5, .bg-gray-600 h6 {
  color: #494949;
}

.bg-gray-600 .nav .open > a, .bg-gray-600 .nav .open > a:focus, .bg-gray-600 .nav .open > a:hover {
  background-color: #cccccc;
  color: #494949 !important;
}

.bg-gray-600 .nav > li > a:focus, .bg-gray-600 .nav > li > a:hover {
  background-color: #cccccc;
  color: #494949 !important;
}

.bg-gray-600 .navbar-nav > li > a {
  color: #494949;
}

.bg-gray-600.small-nav:hover .child-nav {
  background-color: #d9d9d9;
}

.bg-gray-600.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #d9d9d9;
}

.bg-gray-700 {
  background-color: #cccccc;
  border-color: #cccccc;
  color: #494949 !important;
}

.bg-gray-700 h1, .bg-gray-700 h2, .bg-gray-700 h3, .bg-gray-700 h4, .bg-gray-700 h5, .bg-gray-700 h6 {
  color: #494949;
}

.bg-gray-700 .nav .open > a, .bg-gray-700 .nav .open > a:focus, .bg-gray-700 .nav .open > a:hover {
  background-color: #bfbfbf;
  color: #494949 !important;
}

.bg-gray-700 .nav > li > a:focus, .bg-gray-700 .nav > li > a:hover {
  background-color: #bfbfbf;
  color: #494949 !important;
}

.bg-gray-700 .navbar-nav > li > a {
  color: #494949;
}

.bg-gray-700.small-nav:hover .child-nav {
  background-color: #cccccc;
}

.bg-gray-700.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #cccccc;
}

.bg-white {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #494949 !important;
}

.bg-white h1, .bg-white h2, .bg-white h3, .bg-white h4, .bg-white h5, .bg-white h6 {
  color: #494949;
}

.bg-white .nav .open > a, .bg-white .nav .open > a:focus, .bg-white .nav .open > a:hover {
  background-color: #f2f2f2;
  color: #494949 !important;
}

.bg-white .nav > li > a:focus, .bg-white .nav > li > a:hover {
  background-color: #f2f2f2;
  color: #494949 !important;
}

.bg-white .navbar-nav > li > a {
  color: #494949;
}

.bg-white.small-nav:hover .child-nav {
  background-color: #ffffff;
}

.bg-white.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #ffffff;
}

.bg-white-300 {
  background-color: white;
  border-color: white;
  color: #494949 !important;
}

.bg-white-300 h1, .bg-white-300 h2, .bg-white-300 h3, .bg-white-300 h4, .bg-white-300 h5, .bg-white-300 h6 {
  color: #494949;
}

.bg-white-300 .nav .open > a, .bg-white-300 .nav .open > a:focus, .bg-white-300 .nav .open > a:hover {
  background-color: #f2f2f2;
  color: #494949 !important;
}

.bg-white-300 .nav > li > a:focus, .bg-white-300 .nav > li > a:hover {
  background-color: #f2f2f2;
  color: #494949 !important;
}

.bg-white-300 .navbar-nav > li > a {
  color: #494949;
}

.bg-white-300.small-nav:hover .child-nav {
  background-color: white;
}

.bg-white-300.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: white;
}

.bg-white-100 {
  background-color: white;
  border-color: white;
  color: #494949 !important;
}

.bg-white-100 h1, .bg-white-100 h2, .bg-white-100 h3, .bg-white-100 h4, .bg-white-100 h5, .bg-white-100 h6 {
  color: #494949;
}

.bg-white-100 .nav .open > a, .bg-white-100 .nav .open > a:focus, .bg-white-100 .nav .open > a:hover {
  background-color: #f2f2f2;
  color: #494949 !important;
}

.bg-white-100 .nav > li > a:focus, .bg-white-100 .nav > li > a:hover {
  background-color: #f2f2f2;
  color: #494949 !important;
}

.bg-white-100 .navbar-nav > li > a {
  color: #494949;
}

.bg-white-100.small-nav:hover .child-nav {
  background-color: white;
}

.bg-white-100.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: white;
}

.bg-white-500 {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  color: #494949 !important;
}

.bg-white-500 h1, .bg-white-500 h2, .bg-white-500 h3, .bg-white-500 h4, .bg-white-500 h5, .bg-white-500 h6 {
  color: #494949;
}

.bg-white-500 .nav .open > a, .bg-white-500 .nav .open > a:focus, .bg-white-500 .nav .open > a:hover {
  background-color: #e6e6e6;
  color: #494949 !important;
}

.bg-white-500 .nav > li > a:focus, .bg-white-500 .nav > li > a:hover {
  background-color: #e6e6e6;
  color: #494949 !important;
}

.bg-white-500 .navbar-nav > li > a {
  color: #494949;
}

.bg-white-500.small-nav:hover .child-nav {
  background-color: #f2f2f2;
}

.bg-white-500.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #f2f2f2;
}

.bg-white-600 {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #494949 !important;
}

.bg-white-600 h1, .bg-white-600 h2, .bg-white-600 h3, .bg-white-600 h4, .bg-white-600 h5, .bg-white-600 h6 {
  color: #494949;
}

.bg-white-600 .nav .open > a, .bg-white-600 .nav .open > a:focus, .bg-white-600 .nav .open > a:hover {
  background-color: #d9d9d9;
  color: #494949 !important;
}

.bg-white-600 .nav > li > a:focus, .bg-white-600 .nav > li > a:hover {
  background-color: #d9d9d9;
  color: #494949 !important;
}

.bg-white-600 .navbar-nav > li > a {
  color: #494949;
}

.bg-white-600.small-nav:hover .child-nav {
  background-color: #e6e6e6;
}

.bg-white-600.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #e6e6e6;
}

.bg-white-700 {
  background-color: #d9d9d9;
  border-color: #d9d9d9;
  color: #494949 !important;
}

.bg-white-700 h1, .bg-white-700 h2, .bg-white-700 h3, .bg-white-700 h4, .bg-white-700 h5, .bg-white-700 h6 {
  color: #494949;
}

.bg-white-700 .nav .open > a, .bg-white-700 .nav .open > a:focus, .bg-white-700 .nav .open > a:hover {
  background-color: #cccccc;
  color: #494949 !important;
}

.bg-white-700 .nav > li > a:focus, .bg-white-700 .nav > li > a:hover {
  background-color: #cccccc;
  color: #494949 !important;
}

.bg-white-700 .navbar-nav > li > a {
  color: #494949;
}

.bg-white-700.small-nav:hover .child-nav {
  background-color: #d9d9d9;
}

.bg-white-700.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #d9d9d9;
}

/*--------------------------------------------------------------
# COLOR CLASSES
--------------------------------------------------------------*/
.color-primary {
  color: #002e5b !important;
}

.color-danger {
  color: #F44336 !important;
}

.color-success {
  color: #4CAF50 !important;
}

.color-warning {
  color: #FF9800 !important;
}

.color-info {
  color: #607D8B !important;
}

.color-black {
  color: #292929 !important;
}

.color-light-black {
  color: #494949 !important;
}

.color-gray {
  color: #f2f2f2 !important;
}

.color-white {
  color: #ffffff !important;
}

/*--------------------------------------------------------------
# BORDER COLORS
--------------------------------------------------------------*/
.border-primary {
  border-color: #002e5b;
}

.border-primary-300 {
  border-color: #004181;
}

.border-primary-100 {
  border-color: #0055a8;
}

.border-primary-500 {
  border-color: #002142;
}

.border-primary-600 {
  border-color: #001428;
}

.border-primary-700 {
  border-color: #00070f;
}

.border-danger {
  border-color: #F44336;
}

.border-danger-300 {
  border-color: #f6655a;
}

.border-danger-100 {
  border-color: #f8877f;
}

.border-danger-500 {
  border-color: #f32c1e;
}

.border-danger-600 {
  border-color: #ea1c0d;
}

.border-danger-700 {
  border-color: #d2190b;
}

.border-success {
  border-color: #4CAF50;
}

.border-success-300 {
  border-color: #65bc69;
}

.border-success-100 {
  border-color: #80c883;
}

.border-success-500 {
  border-color: #449d48;
}

.border-success-600 {
  border-color: #3d8b40;
}

.border-success-700 {
  border-color: #357a38;
}

.border-warning {
  border-color: #FF9800;
}

.border-warning-300 {
  border-color: #ffa726;
}

.border-warning-100 {
  border-color: #ffb74d;
}

.border-warning-500 {
  border-color: #e68900;
}

.border-warning-600 {
  border-color: #cc7a00;
}

.border-warning-700 {
  border-color: #b36a00;
}

.border-info {
  border-color: #607D8B;
}

.border-info-300 {
  border-color: #73909e;
}

.border-info-100 {
  border-color: #8aa2ae;
}

.border-info-500 {
  border-color: #566f7c;
}

.border-info-600 {
  border-color: #4b626d;
}

.border-info-700 {
  border-color: #41545e;
}

.border-black {
  border-color: #292929;
}

.border-black-300 {
  border-color: #3c3c3c;
}

.border-black-100 {
  border-color: #4f4f4f;
}

.border-black-500 {
  border-color: #1c1c1c;
}

.border-black-600 {
  border-color: #101010;
}

.border-black-700 {
  border-color: #030303;
}

.border-gray {
  border-color: #f2f2f2;
}

.border-gray-300 {
  border-color: white;
}

.border-gray-100 {
  border-color: white;
}

.border-gray-500 {
  border-color: #e5e5e5;
}

.border-gray-600 {
  border-color: #d9d9d9;
}

.border-gray-700 {
  border-color: #cccccc;
}

.z-1 {
  z-index: 1;
}

.mt-n {
  margin-top: 0 !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-n {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.ml-n {
  margin-left: 0 !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.mr-n {
  margin-right: 0 !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.m-n {
  margin: 0 !important;
}

.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-35 {
  margin: 35px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-45 {
  margin: 45px !important;
}

.m-50 {
  margin: 50px !important;
}

.pt-n {
  padding-top: 0 !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-n {
  padding-bottom: 0 !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pl-n {
  padding-left: 0 !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pr-n {
  padding-right: 0 !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.p-n {
  padding: 0 !important;
}

.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-35 {
  padding: 35px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-45 {
  padding: 45px !important;
}

.p-50 {
  padding: 50px !important;
}

.ptb-5 {
  padding: 5px 0 !important;
}

.ptb-10 {
  padding: 10px 0 !important;
}

.ptb-15 {
  padding: 15px 0 !important;
}

.ptb-20 {
  padding: 20px 0 !important;
}

.ptb-25 {
  padding: 25px 0 !important;
}

.ptb-30 {
  padding: 30px 0 !important;
}

.ptb-35 {
  padding: 35px 0 !important;
}

.ptb-40 {
  padding: 40px 0 !important;
}

.ptb-45 {
  padding: 45px 0 !important;
}

.ptb-50 {
  padding: 50px 0 !important;
}

/*--------------------------------------------------------------
# BORDERS
--------------------------------------------------------------*/
.no-border {
  border-width: 0px !important;
}

.no-border-top {
  border-top-width: 0px !important;
}

.no-border-bottom {
  border-bottom-width: 0px !important;
}

.no-border-left {
  border-left-width: 0px !important;
}

.no-border-right {
  border-right-width: 0px !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-1-top {
  border-top-width: 1px !important;
}

.border-1-bottom {
  border-bottom-width: 1px !important;
}

.border-1-left {
  border-left-width: 1px !important;
}

.border-1-right {
  border-right-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-2-top {
  border-top-width: 2px !important;
}

.border-2-bottom {
  border-bottom-width: 2px !important;
}

.border-2-left {
  border-left-width: 2px !important;
}

.border-2-right {
  border-right-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-3-top {
  border-top-width: 3px !important;
}

.border-3-bottom {
  border-bottom-width: 3px !important;
}

.border-3-left {
  border-left-width: 3px !important;
}

.border-3-right {
  border-right-width: 3px !important;
}

/*--------------------------------------------------------------
# BORDER RADIUS
--------------------------------------------------------------*/
.no-radius {
  border-radius: 0px !important;
}

.no-radius-top-left {
  border-top-left-radius: 0px !important;
}

.no-radius-top-right {
  border-top-right-radius: 0px !important;
}

.no-radius-bottom-right {
  border-bottom-right-radius: 0px !important;
}

.no-radius-bottom-left {
  border-bottom-left-radius: 0px !important;
}

.border-rad-2 {
  border-radius: 2px !important;
}

.border-rad-2-top-left {
  border-top-left-radius: 2px !important;
}

.border-rad-2-top-right {
  border-top-right-radius: 2px !important;
}

.border-rad-2-bottom-right {
  border-bottom-right-radius: 2px !important;
}

.border-rad-2-bottom-left {
  border-bottom-left-radius: 2px !important;
}

.border-rad-4 {
  border-radius: 4px !important;
}

.border-rad-4-top-left {
  border-top-left-radius: 4px !important;
}

.border-rad-4-top-right {
  border-top-right-radius: 4px !important;
}

.border-rad-4-bottom-right {
  border-bottom-right-radius: 4px !important;
}

.border-rad-4-bottom-left {
  border-bottom-left-radius: 4px !important;
}

.border-rad-6 {
  border-radius: 6px !important;
}

.border-rad-6-top-left {
  border-top-left-radius: 6px !important;
}

.border-rad-6-top-right {
  border-top-right-radius: 6px !important;
}

.border-rad-6-bottom-right {
  border-bottom-right-radius: 6px !important;
}

.border-rad-6-bottom-left {
  border-bottom-left-radius: 6px !important;
}

.overflow-hidden {
  overflow: hidden;
}

/*--------------------------------------------------------------
# 2. TOP NAVBAR
--------------------------------------------------------------*/
@media only screen and (min-width: 992px) {
  .two-column-dropdown {
    min-width: 500px;
  }
}

.navbar-toggleable-lg .navbar-nav .nav-link {
  padding: .875rem 1.5rem;
}

.navbar {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background: #002e5b;
}

.dropdown-header {
  color: #636c72 !important;
}

.navbar.bg-primary, .navbar.bg-white {
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

.dropdown-menu {
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

.dropdown-item .icon, .dropdown-item .fa {
  margin-right: 5px;
  color: #494949;
  font-size: 18px;
}

.dropdown-menu .dropdown-cta {
  width: 100%;
  height: 100%;
  background: #494949;
  color: #ffffff;
  text-align: center;
  /* padding: 1rem; */
}

.dropdown-menu .dropdown-cta .card {
  height: 100%;
}

@media (min-width: 1200px) {
  .dropdown-menu:before {
    position: absolute;
    top: -8px;
    left: 30px;
    display: inline-block;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #636c72;
    border-left: 8px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
  }
  .dropdown-menu:after {
    position: absolute;
    top: -7px;
    left: 30px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ffffff;
    border-left: 7px solid transparent;
    content: '';
  }
  .dropdown-menu.dropdown-menu-right:before {
    left: auto;
    right: 30px;
  }
  .dropdown-menu.dropdown-menu-right:after {
    left: auto;
    right: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .white-on-scroll .navbar-nav {
    background: #fff;
  }
  .navbar-toggler {
    margin-top: 0.5rem;
  }
  .navbar-toggle-icon {
    padding: 0.25rem;
  }
  .navbar-inverse .navbar-toggler {
    border: 0px;
  }
  .primary-on-scroll .navbar-nav {
    background: #002e5b;
  }
  .bg-white .navbar-toggle-icon, .white-on-scroll .navbar-toggle-icon {
    color: #494949;
    padding: 0.25rem;
  }
  .bg-white .dropdown-menu, .white-on-scroll .dropdown-menu {
    box-shadow: none;
  }
  .mob-nav {
    min-height: 50px;
  }
  .navbar-nav {
    margin-top: 60px;
  }
  .nav-link .fa {
    width: 20px;
    text-align: center;
  }
}

@media only screen and (max-width: 576px) {
  .mob-100 {
    width: 100%;
  }
}

.top-navbar-icons {
  font-size: 2rem;
}

.navbar-inverse .navbar-nav .nav-link {
  color: #ffffff;
}

.navbar.navbar-inverse {
  padding: 0 1rem;
}

.nav-item.active .nav-link {
  background-color: #002142;
}

/*--------------------------------------------------------------
# 3. COVER
--------------------------------------------------------------*/
.cover-wrapper {
  display: table;
  width: 100%;
  height: 70vh;
  /* For at least Firefox */
  min-height: 70%;
  background-image: url("../images/img-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.cover-wrapper-inner {
  display: table-cell;
  vertical-align: top;
  background: rgba(41, 41, 41, 0.5);
}

.cover-container {
  margin-right: auto;
  margin-left: auto;
  text-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #ffffff;
}

/* Padding for spacing */
.inner {
  padding: 2rem;
}

/* Start the vertical centering */
.cover-wrapper-inner {
  vertical-align: middle;
}

@media (min-width: 40em) {
  /* Handle the widths */
  .cover-container,
  .mastfoot,
  .masthead {
    width: 100%;
    /* Must be percentage or pixels for horizontal alignment */
  }
}

@media (max-width: 40em) {
  .cover-cta .form-inline .form-control {
    margin-bottom: 10px;
  }
  .cover-cta .form-inline .btn {
    margin-bottom: 10px;
  }
  .card-text:last-child {
    margin-bottom: 10px;
  }
  .post .st-post-thumb {
    height: 225px !important;
  }
}

@media (min-width: 62em) {
  .cover-container,
  .mastfoot,
  .masthead {
    width: 42rem;
  }
}

/*--------------------------------------------------------------
# 4. PAGE TITLE
--------------------------------------------------------------*/
.page-title-wrapper {
  display: table;
  width: 100%;
  height: 50vh;
  /* For at least Firefox */
  min-height: 100%;
  background-image: url("../images/img-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /* Padding for spacing */
  /* Start the vertical centering */
}

.page-title-wrapper .page-title-wrapper-inner {
  display: table-cell;
  vertical-align: top;
  background: rgba(41, 41, 41, 0.5);
}

.page-title-wrapper .page-title-container {
  margin-right: auto;
  margin-left: auto;
  text-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #ffffff;
}

.page-title-wrapper .inner {
  padding: 2rem;
}

.page-title-wrapper .page-title-wrapper-inner {
  vertical-align: middle;
}

@media (min-width: 40em) {
  /* Handle the widths */
  .page-title-container {
    width: 100%;
  }
}

@media (min-width: 62em) {
  .page-title-container {
    width: 42rem;
  }
}

.page-title-wrapper-2 {
  display: table;
  width: 100%;
  height: 50vh;
  /* For at least Firefox */
  min-height: 100%;
  background-image: url("../images/pattern.png");
  /* Padding for spacing */
  /* Start the vertical centering */
}

.page-title-wrapper-2 .page-title-wrapper-inner {
  display: table-cell;
  vertical-align: top;
}

.page-title-wrapper-2 .page-title-container {
  margin-right: auto;
  margin-left: auto;
  text-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #ffffff;
}

.page-title-wrapper-2 .inner {
  padding: 2rem;
}

.page-title-wrapper-2 .page-title-wrapper-inner {
  vertical-align: middle;
}

.page-title-wrapper-2 .breadcrumb a {
  color: rgba(255, 255, 255, 0.7);
}

.page-title-wrapper-2 .breadcrumb a:hover {
  color: white;
}

.page-title-wrapper-2 .breadcrumb .breadcrumb-item {
  float: none;
  display: inline-block;
}

.page-title-wrapper-3 {
  display: table;
  width: 100%;
  height: 50vh;
  min-height: 100%;
  background-image: url("../images/pattern-light.png");
  /* Padding for spacing */
  /* Start the vertical centering */
}

.page-title-wrapper-3 .page-title-wrapper-inner {
  display: table-cell;
  vertical-align: top;
}

.page-title-wrapper-3 .page-title-container {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  color: #292929;
}

.page-title-wrapper-3 .inner {
  padding: 2rem;
}

.page-title-wrapper-3 .page-title-wrapper-inner {
  vertical-align: middle;
}

.page-title-wrapper-3 .breadcrumb a {
  color: rgba(41, 41, 41, 0.7);
}

.page-title-wrapper-3 .breadcrumb a:hover {
  color: #292929;
}

.page-title-wrapper-3 .breadcrumb .breadcrumb-item {
  float: none;
  display: inline-block;
}

@media only screen and (max-width: 991px) {
  .page-title-wrapper {
    min-height: 70vh !important;
  }
  .page-title-wrapper-2 {
    min-height: 70vh !important;
  }
  .page-title-wrapper-3 {
    min-height: 70vh !important;
  }
  .page-title-wrapper-inner {
    padding-top: 50px;
    padding-bottom: 20px;
  }
}

.page-title-wrapper {
  display: table;
  width: 100%;
  height: 50vh;
  /* For at least Firefox */
  min-height: 100%;
  background-image: url("../images/img-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /* Padding for spacing */
  /* Start the vertical centering */
}

.page-title-wrapper .page-title-wrapper-inner {
  display: table-cell;
  vertical-align: top;
  background: rgba(0, 0, 0, 0.25);
}

.page-title-wrapper .page-title-container {
  margin-right: auto;
  margin-left: auto;
  text-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #ffffff;
}

.page-title-wrapper .inner {
  padding: 2rem;
}

.page-title-wrapper .page-title-wrapper-inner {
  vertical-align: middle;
}

@media (min-width: 40em) {
  /* Handle the widths */
  .page-title-container {
    width: 100%;
  }
}

@media (min-width: 62em) {
  .page-title-container {
    width: 42rem;
  }
}

/*--------------------------------------------------------------
# 5. CTA
--------------------------------------------------------------*/
.cover-cta {
  margin-top: -50px;
}

.cover-cta .form-control {
  width: 250px;
}

.cover-cta .form-inline {
  float: right;
}

.cover-cta .card {
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #002e5b;
}

@media only screen and (max-width: 991px) {
  .cover-cta {
    text-align: center;
  }
  .cover-cta .form-inline {
    float: none;
    justify-content: center;
  }
  .cover-cta .form-inline .form-control {
    width: auto;
  }
}

.particles-cta-section {
  padding: 2rem 0;
  color: #ffffff;
  position: relative;
  margin-top: -17px;
}

.particles-cta-section .particles-content {
  z-index: 1;
  position: relative;
}

.particles-cta-section .particles-cta {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

/*--------------------------------------------------------------
# 6. PARTICLES
--------------------------------------------------------------*/
.particles-bg {
  height: 75vh;
}

.animated-cover-bg .cover-wrapper {
  background: none;
  position: absolute;
  top: 140px;
  left: 0;
  width: 100%;
  height: 75vh;
}

.animated-cover-bg .cover-wrapper-inner {
  background: none;
}

/*--------------------------------------------------------------
# 7. CARDS
--------------------------------------------------------------*/
.news-card {
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

.card-img-overlay {
  background: #292929;
  background: rgba(41, 41, 41, 0.6);
  background: linear-gradient(rgba(41, 41, 41, 0.8), rgba(41, 41, 41, 0));
}

.card-img-overlay.only-img {
  bottom: auto;
}

.card-inverse {
  color: rgba(255, 255, 255, 0.75);
}

.card.info-on-hover .card-img-overlay {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.card.info-on-hover:hover .card-img-overlay {
  opacity: 1;
}

.card-shadow {
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

.card-icon-block {
  position: relative;
}

.card-icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  margin-top: -50px;
  margin-left: 25%;
  position: absolute;
}

/*--------------------------------------------------------------
# 8. SIDEBAR
--------------------------------------------------------------*/
.sidebar .list-group-item {
  padding: 0;
}

.sidebar .list-group-item.active {
  background-color: inherit;
  border: 0px;
  border-left: 3px solid #002e5b;
}

.sidebar .list-group-item a {
  display: block;
  padding: 0.75rem 1.25rem;
  width: 100%;
}

.sidebar .list-group-item a:hover {
  background: #002e5b;
  color: #ffffff;
}

/*--------------------------------------------------------------
# 9. FOOTER
--------------------------------------------------------------*/
.st-footer {
  background-color: #292929;
  padding-top: 4rem;
  padding-bottom: 2rem;
  color: rgba(255, 255, 255, 0.5);
}

.st-footer .column-title {
  color: rgba(255, 255, 255, 0.8);
}

.st-footer .nav-link {
  color: rgba(255, 255, 255, 0.5);
  padding: 0.25rem 0;
}

.st-footer .nav-link:hover {
  color: rgba(255, 255, 255, 0.7);
}

.bg-footer {
  background: url("../images/pattern.png");
}

/*footer title for elements page*/
.title-separator-bar {
  border-bottom: 1px solid #fff;
  width: 30%;
  margin: 10px 0 15px;
}

/*--------------------------------------------------------------
# 10. BOXED & WIDE
--------------------------------------------------------------*/
.boxed .main-wrapper-inner {
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

/*--------------------------------------------------------------
# 11. MAP
--------------------------------------------------------------*/
.map-wrapper .map {
  width: 100%;
  height: 400px;
  pointer-events: none;
}

.map-wrapper .map.clicked {
  pointer-events: all;
}

/*--------------------------------------------------------------
# 12. TIMELINE
--------------------------------------------------------------*/
.timeline {
  list-style: none;
  padding: 20px 0;
  position: relative;
}

.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #636c72;
  left: 50%;
  margin-left: -1.5px;
}

.timeline > li {
  margin-bottom: 20px;
  position: relative;
}

.timeline > li:after,
.timeline > li:before {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li:after,
.timeline > li:before {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li > .timeline-panel {
  width: 46%;
  float: left;
  position: relative;
}

.timeline > li > .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #ccc;
  border-right: 0 solid #ccc;
  border-bottom: 15px solid transparent;
  content: " ";
}

.timeline > li > .timeline-panel:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}

.timeline > li > .timeline-badge {
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  left: 50%;
  margin-left: -25px;
  background-color: #636c72;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.timeline-badge.primary {
  background-color: #002e5b !important;
}

.timeline-badge.success {
  background-color: #4CAF50 !important;
}

.timeline-badge.warning {
  background-color: #FF9800 !important;
}

.timeline-badge.danger {
  background-color: #F44336 !important;
}

.timeline-badge.info {
  background-color: #607D8B !important;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

@media (max-width: 767px) {
  ul.timeline:before {
    left: 40px;
  }
  ul.timeline > li > .timeline-panel {
    width: calc(100% - 90px);
    width: -moz-calc(100% - 90px);
    width: -webkit-calc(100% - 90px);
  }
  ul.timeline > li > .timeline-badge {
    left: 15px;
    margin-left: 0;
    top: 16px;
  }
  ul.timeline > li > .timeline-panel {
    float: right;
  }
  ul.timeline > li > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }
  ul.timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}

/*--------------------------------------------------------------
# 13. SECTIONS
--------------------------------------------------------------*/
.content-section {
  padding: 4rem 0;
}

.white-section {
  background: #ffffff;
}

.cards-section {
  margin-bottom: 2rem;
  margin-top: 3rem;
}

section.parallax {
  background-image: url("../images/img-1.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

section.parallax .light-overlay, section.parallax .dark-overlay, section.parallax .primary-overlay {
  padding: 4rem 0;
}

.light-overlay {
  background: rgba(255, 255, 255, 0.7);
}

.dark-overlay {
  background: rgba(41, 41, 41, 0.7);
}

.primary-overlay {
  background: rgba(0, 46, 91, 0.7);
}

.bg-dark-grad-hor-1 {
  background: linear-gradient(to right, #070000 1%, #4c0001 50%, #070000 100%) !important;
}

.bg-dark-grad-hor-2 {
  background: linear-gradient(to right, #c33764 1%, #070000 100%) !important;
}

.bg-light-grad-ver-1 {
  background: linear-gradient(to bottom, #bddff7 0%, #f9eee0 100%) !important;
}

.bg-dark-grad-rad-1 {
  background: #36D1DC;
  background: -webkit-linear-gradient(to right, #5B86E5, #36D1DC);
  background: linear-gradient(to right, #5B86E5, #36D1DC);
}

.bg-light-grad-hor-2 {
  background: linear-gradient(to right, #7affff 0%, #3fff69 100%) !important;
}

.bg-img-2 {
  background-image: url("../images/img-2.jpg") !important;
}

.bg-img-3 {
  background-image: url("../images/img-3.jpg") !important;
}

.bg-img-4 {
  background-image: url("../images/img-4.jpg") !important;
}

.bg-img-5 {
  background-image: url("../images/img-5.jpg") !important;
}

.bg-img-6 {
  background-image: url("../images/img-6.jpg") !important;
}

.bg-img-7 {
  background-image: url("../images/img-7.jpg") !important;
}

.bg-img-testimonial {
  background-image: url("../images/sunset.jpg") !important;
}

.bg-parallax-1 {
  background-image: url(../images/parallax/water-lily-left.jpg) !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-img-8 {
  background-image: url("../images/calculator.jpg") !important;
}

@media only screen and (max-width: 576px) {
  .counter-boxes {
    text-align: center;
  }
}

/*--------------------------------------------------------------
# 14. ACCORDIONS
--------------------------------------------------------------*/
.accordion-wrap .card {
  margin-bottom: 0.5rem;
}

.accordion-wrap .card .card-header {
  padding: 0;
}

.accordion-wrap .card .card-header a {
  display: block;
  padding: 0.75rem 1.25rem;
}

.accordion-wrap .card .card-header a .icon {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.accordion-wrap .card .card-header a.collapsed .icon {
  transform: rotate(-90deg);
}

/*--------------------------------------------------------------
# 15. CAROUSEL
--------------------------------------------------------------*/
/* Carousel base class */
.carousel {
  margin-bottom: 1rem;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  z-index: 10;
  bottom: 3rem;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 450px;
  background-color: transparent;
}

.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 450px;
}

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }
}

/*--------------------------------------------------------------
# 16. OWL CAROUSEL
--------------------------------------------------------------*/
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav [class*='owl-'] {
  color: #ffffff;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #d9d9d9;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.owl-theme .owl-nav [class*='owl-']:hover {
  background: #002e5b;
  color: #ffffff;
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #d9d9d9;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #002e5b;
}

.owl-theme .owl-nav [class*='owl-'] {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.owl-carousel {
  position: relative;
}

.owl-carousel .owl-nav {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.owl-carousel .owl-nav .owl-prev {
  float: left;
  margin-left: -30px;
}

.owl-carousel .owl-nav .owl-next {
  float: right;
  margin-right: -30px;
}

@media only screen and (max-width: 768px) {
  .owl-carousel .owl-nav .owl-prev {
    display: none !important;
  }
  .owl-carousel .owl-nav .owl-next {
    display: none !important;
  }
}

.testimonials-design {
  height: 150px;
  width: 150px !important;
  border-radius: 50%;
  display: inline-block !important;
  border: 5px inset rebeccapurple;
}

/*--------------------------------------------------------------
# 17. FEATURES
--------------------------------------------------------------*/
.feature-icon {
  font-size: 2rem;
}

.one-feature {
  padding-bottom: 1.5rem;
}

/*--------------------------------------------------------------
# 18. BLOG
--------------------------------------------------------------*/
.entry-header {
  margin-bottom: 15px;
}

.entry-header .entry-title {
  font-size: 1.5rem;
}

.entry-header .entry-meta {
  color: #767676;
  font-size: 0.8rem;
}

.entry-header .entry-meta a {
  color: #767676;
}

.entry-header .entry-meta span.meta-span {
  margin-right: 15px;
}

.entry-header .entry-meta span.meta-span .fa {
  margin-right: 5px;
}

.post {
  margin-bottom: 30px;
}

.post .st-post-thumb {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  border-radius: 4px 4px 0 0;
}

.post .st-post-thumb img.attachment-post-thumbnail {
  position: absolute;
  left: 50%;
  top: 50%;
  height: auto;
  transform: translate(-50%, -50%);
}

.col-md-6 .post .st-post-thumb {
  height: 200px;
}

/*--------------------------------------------------------------
# 19. MISCELLANEOUS
--------------------------------------------------------------*/
.breadcrumb {
  background: transparent;
  font-size: 0.85rem;
}

th, strong {
  font-weight: 500;
}

[data-toggle="popover"] {
  cursor: pointer;
}

.faq-div {
  margin-top: 2rem;
}

.btn-primary:focus {
  color: #ffffff;
}

@media (max-width: 576px) {
  .btn {
    white-space: inherit;
  }
}

button, input, optgroup, select, textarea {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.banner-carousel .carousel-item > img {
  height: 70vh !important;
}

.banner-carousel .carousel-item {
  height: 70vh !important;
}

.banner-carousel .carousel-caption {
  bottom: 21vh !important;
}

.banner-carousel .dark-overlay {
  background: rgba(0, 0, 0, 0.4);
}

@media only screen and (max-width: 768px) {
  .banner-carousel .carousel-caption {
    bottom: 12vh !important;
  }
}

.scroll-top-button {
  position: fixed;
  bottom: -50px;
  right: 15px;
  background: #292929;
  background: rgba(41, 41, 41, 0.5);
  padding: 10px 15px;
  color: #f2f2f2;
  border-radius: 0.25rem;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

@media (max-width: 576px) {
  .scroll-top-button {
    padding: 5px 10px;
  }
}

/*blockquote*/
.blockquote {
  border-left: 0.25rem solid #002e5b;
}

.blockquote-reverse {
  border-right: 0.25rem solid #002e5b;
  font-size: 1.25rem;
}

/*banner dotted image overlay*/
.banner-img {
  clear: both;
  padding: 0;
  position: relative;
  margin: 0 -1px;
  height: 60vh;
  overflow: hidden;
  background-color: #e5e5e5;
}

.banner-img .img_wrapper {
  position: relative;
  width: 100%;
  height: 60vh;
  box-shadow: 1px 1px 50px #4A4B45;
  -moz-box-shadow: 1px 1px 50px #4A4B45;
  -webkit-box-shadow: 1px 1px 50px #4A4B45;
  margin: 0 auto 0;
}

.banner-img .post-image {
  margin: 0px 0px 0px 1px !important;
  display: block;
  height: 60vh;
  position: relative;
  width: 100%;
}

.banner-img .banner-title {
  bottom: 30px;
  left: 18px;
  position: absolute;
}

.banner-img .banner-title h2 {
  background: url("../images/background-pattern/blue-dot.png") repeat;
  padding: 10px 20px;
  color: #fcfcfc;
  float: left;
}

.bganner-h {
  position: absolute;
  left: 0;
  top: 0;
  background: url("../images/background-pattern/dot.png") repeat;
  width: 100%;
  height: 60vh;
  z-index: 100;
}

.banner-mid {
  margin: 0 auto;
  position: relative;
  top: -50px;
}

/* END banner dotted image overlay*/
/* Top contact-bar*/
.contact-bar {
  padding: 25px 0 5px 0;
  background: #ffffff;
}

.contact-bar .media .fa {
  font-size: 1.5rem;
  margin-right: .875rem;
}

.contact-bar .media small {
  font-size: 0.75rem;
}

@media (max-width: 768px) {
  .logo-col {
    text-align: center;
  }
}

/* END Top contact-bar*/
.career-resume {
  background: #f2f2f2;
  padding: 20px;
  border-left: 5px solid #F44336;
}

/*newsletter-box*/
.newsletter-box input {
  width: 50%;
  margin-bottom: 20px;
  margin: auto;
}

/*Home card image blur overlay*/
.img-over-text {
  max-width: 100%;
  min-height: 300px;
  position: relative;
  overflow: hidden;
  border-top-right-radius: calc(0.25rem - 1px);
  border-top-left-radius: calc(0.25rem - 1px);
}

.img-over-text > div {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 10px;
  background: inherit;
  background-attachment: fixed;
  overflow: hidden;
}

.img-over-text > div::before {
  content: "";
  position: absolute;
  top: -20px;
  left: 0;
  width: 200%;
  height: 200%;
  background: inherit;
  background-attachment: fixed;
  -webkit-filter: blur(4px);
  filter: blur(4px);
}

.img-over-text > div::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
}

.img-over-text > div > h5 {
  margin: 0;
  color: white;
  position: relative;
  z-index: 1;
}

.bg-img-over-text-1 {
  background: url("../images/card/card-2.jpg");
  background-position: center;
}

.bg-img-over-text-2 {
  background: url("../images/card/card-1.jpg");
  background-position: center;
}

.bg-img-over-text-3 {
  background: url("../images/card/card-3.jpg");
  background-position: center;
}

/* END Home card image blur overlay*/
/*banner dotted image overlay*/
.banner-img {
  clear: both;
  padding: 0;
  position: relative;
  margin: 0 -1px;
  height: 60vh;
  overflow: hidden;
  background-color: #e5e5e5;
}

.banner-img .img_wrapper {
  position: relative;
  width: 100%;
  height: 60vh;
  box-shadow: 1px 1px 50px #4A4B45;
  -moz-box-shadow: 1px 1px 50px #4A4B45;
  -webkit-box-shadow: 1px 1px 50px #4A4B45;
  margin: 0 auto 0;
}

.banner-img .post-image {
  margin: 0px 0px 0px 1px !important;
  display: block;
  height: 60vh;
  position: relative;
  width: 100%;
}

.banner-img .banner-title {
  bottom: 30px;
  left: 18px;
  position: absolute;
  z-index: 999;
}

.banner-img .banner-title h2 {
  background: url("../images/background-pattern/blue-dot.png") repeat;
  padding: 10px 20px;
  color: #fcfcfc;
  float: left;
}

.bganner-h {
  position: absolute;
  left: 0;
  top: 0;
  background: url("../images/background-pattern/diagonal line.png") repeat;
  width: 100%;
  height: 60vh;
  z-index: 100;
}

.bganner-h1 {
  position: absolute;
  left: 0;
  top: 0;
  background: url("../images/background-pattern/dot.png") repeat;
  width: 100%;
  height: 60vh;
  z-index: 100;
}

.banner-mid {
  margin: 0 auto;
  position: relative;
  top: -50px;
}

/* END banner dotted image overlay*/
