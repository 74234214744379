/*!--------------------------------------------------------------
# main.scss
#
# Sass file for a template.
# Author: SaltTechno
#
#
--------------------------------------------------------------*/



/*--------------------------------------------------------------
# [TABLE OF CONTENTS]
#
# 1. VARIABLES & MIXINS
# 2. TOP NAVBAR
# 3. COVER
# 4. PAGE TITLE
# 5. CTA
# 6. PARTICLES
# 7. CARDS
# 8. SIDEBAR
# 9. FOOTER
# 10. BOXED & WIDE
# 11. MAP
# 12. TIMELINE
# 13. SECTIONS
# 14. ACCORDIONS
# 15. CAROUSEL
# 16. OWL CAROUSEL
# 17. FEATURES
# 18. BLOG
# 19. MISCELLANEOUS
--------------------------------------------------------------*/



/*--------------------------------------------------------------
# 1. VARIABLES & MIXINS
--------------------------------------------------------------*/

// Import Google Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto|Montserrat');

@import "variables";

// Import bourbon
@import "bourbon/bourbon";

$body-color: $light-black-color;

body{
    font-family: $st-body-font;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    background-color: $gray-color;
    color: $light-black-color;
    text-rendering: optimizeLegibility !important;
    letter-spacing: 0.5px;
    overflow-x: hidden;
}
@include selection {
    background-color: $black-color;
    color: $white-color;
}
a {
    outline: none !important;
    text-decoration: none !important;
    color: $light-black-color;
    @include transition(all 0.4s ease-in-out);
}

// COLOR PALETTE CLASSES
@import "color-classes";

// HELPER CLASSES
@import "helpers";




/*--------------------------------------------------------------
# 2. TOP NAVBAR
--------------------------------------------------------------*/
@media only screen and (min-width: 992px) {
    .two-column-dropdown {
        min-width: 500px;
    }
}
.navbar-toggleable-lg .navbar-nav .nav-link {
    padding: .875rem 1.5rem;
}
.navbar {
    @include transition(all 0.4s ease-in-out);
    background: $primary-color;
}
.dropdown-header {
    color: $gray-light !important;
}
.navbar.bg-primary, .navbar.bg-white {
    box-shadow: 0 2px 15px rgba(0,0,0,0.1);
}
.dropdown-menu{
    box-shadow: 0 2px 15px rgba(0,0,0,0.1);
}
.dropdown-item {
    .icon, .fa {
        margin-right: 5px;
        color: $light-black-color;
        font-size: 18px;
    }
}
.dropdown-menu {
    .dropdown-cta {
        width: 100%;
        height: 100%;
        background: $light-black-color;
        color: $white-color;
        text-align: center;
        /* padding: 1rem; */
        .card {
            height: 100%;
        }
    }
}
@media (min-width: 1200px) {
    .dropdown-menu{
        &:before {
            position: absolute;
            top: -8px;
            left: 30px;
            display: inline-block;
            border-right: 8px solid transparent;
            border-bottom: 8px solid $gray-light;
            border-left: 8px solid transparent;
            border-bottom-color: rgba(0, 0, 0, 0.2);
            content: '';
        }
        &:after {
            position: absolute;
            top: -7px;
            left: 30px;
            display: inline-block;
            border-right: 7px solid transparent;
            border-bottom: 7px solid $white-color;
            border-left: 7px solid transparent;
            content: '';
        }
        &.dropdown-menu-right {
            &:before {
                left: auto;
                right: 30px;
            }
            &:after {
                left: auto;
                right: 30px;
            }
        }
    }
}
@media only screen and (max-width: 1200px) {
    .white-on-scroll {
        .navbar-nav {
            background: #fff;
        }
    }
    .navbar-toggler {
        margin-top: 0.5rem;
    }
    .navbar-toggle-icon {
        padding: 0.25rem;
    }
    .navbar-inverse .navbar-toggler {
        border: 0px;
    }
    .primary-on-scroll {
        .navbar-nav {
            background: $primary-color;
        }
    }
    .bg-white, .white-on-scroll {
        .navbar-toggle-icon {
            color: $light-black-color;
            padding: 0.25rem;
        }
        .dropdown-menu {
            box-shadow: none;
        }
    }
    .mob-nav {
        min-height: 50px;
    }
    .navbar-nav {
        margin-top: 60px;
    }
    .nav-link .fa {
        width: 20px;
        text-align: center;
    }
}
@media only screen and (max-width: 576px) {
    .mob-100 {
        width: 100%;
    }
}
.top-navbar-icons {
    font-size: 2rem;
}
.navbar-inverse .navbar-nav .nav-link {
    color: $white-color;
}
.navbar.navbar-inverse {
    padding: 0 1rem;
}
.nav-item.active {
    .nav-link {
        background-color: darken($primary-color, 5%);
    }
}



/*--------------------------------------------------------------
# 3. COVER
--------------------------------------------------------------*/
.cover-wrapper {
	display: table;
	width: 100%;
	height: 70vh;
	/* For at least Firefox */
	min-height: 70%;
    background-image: url('../images/img-1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.cover-wrapper-inner {
	display: table-cell;
	vertical-align: top;
    background: rgba($black-color, 0.5);
}

.cover-container {
	margin-right: auto;
	margin-left: auto;
    text-shadow: 0 .05rem .1rem rgba(0,0,0,.5);
    text-align: center;
    color: $white-color;
}
/* Padding for spacing */
.inner {
	padding: 2rem;
}
/* Start the vertical centering */
.cover-wrapper-inner {
    vertical-align: middle;
}
@media (min-width: 40em) {

	/* Handle the widths */
	.cover-container,
	.mastfoot,
	.masthead {
		width: 100%;
		/* Must be percentage or pixels for horizontal alignment */
	}
}
@media (max-width: 40em) {
    .cover-cta {
        .form-inline {
            .form-control {
                margin-bottom: 10px;
            }
            .btn {
                margin-bottom: 10px;
            }
        }
    }
    .card-text {
        &:last-child {
            margin-bottom: 10px;
        }
    }
    .post {
        .st-post-thumb {
            height: 225px !important;
        }
    }
}
@media (min-width: 62em) {
	.cover-container,
	.mastfoot,
	.masthead {
		width: 42rem;
	}
}



/*--------------------------------------------------------------
# 4. PAGE TITLE
--------------------------------------------------------------*/
.page-title-wrapper {
	display: table;
	width: 100%;
	height: 50vh;
	/* For at least Firefox */
	min-height: 100%;
    background-image: url('../images/img-1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    .page-title-wrapper-inner {
    	display: table-cell;
    	vertical-align: top;
        background: rgba($black-color, 0.5);
    }

    .page-title-container {
    	margin-right: auto;
    	margin-left: auto;
        text-shadow: 0 .05rem .1rem rgba(0,0,0,.5);
        text-align: center;
        color: $white-color;
    }
    /* Padding for spacing */
    .inner {
    	padding: 2rem;
    }
    /* Start the vertical centering */
    .page-title-wrapper-inner {
        vertical-align: middle;
    }
}

@media (min-width: 40em) {
	/* Handle the widths */
	.page-title-container {
		width: 100%;
	}
}
@media (min-width: 62em) {
	.page-title-container{
		width: 42rem;
	}
}

.page-title-wrapper-2 {
    display: table;
	width: 100%;
	height: 50vh;
	/* For at least Firefox */
	min-height: 100%;
    background-image: url('../images/pattern.png');
    .page-title-wrapper-inner {
    	display: table-cell;
    	vertical-align: top;
    }
    .page-title-container {
    	margin-right: auto;
    	margin-left: auto;
        text-shadow: 0 .05rem .1rem rgba(0,0,0,.5);
        text-align: center;
        color: $white-color;
    }
    /* Padding for spacing */
    .inner {
    	padding: 2rem;
    }
    /* Start the vertical centering */
    .page-title-wrapper-inner {
        vertical-align: middle;
    }
    .breadcrumb {
        a {
            color: rgba($white-color, 0.7);
            &:hover {
                color: rgba($white-color, 1);
            }
        }
        .breadcrumb-item {
            float: none;
            display: inline-block;
        }
    }
}

.page-title-wrapper-3 {
    display: table;
	width: 100%;
	height: 50vh;
	min-height: 100%;
    background-image: url('../images/pattern-light.png');
    .page-title-wrapper-inner {
    	display: table-cell;
    	vertical-align: top;
    }
    .page-title-container {
    	margin-right: auto;
    	margin-left: auto;
        text-align: center;
        color: $black-color;
    }
    /* Padding for spacing */
    .inner {
    	padding: 2rem;
    }
    /* Start the vertical centering */
    .page-title-wrapper-inner {
        vertical-align: middle;
    }
    .breadcrumb {
        a {
            color: rgba($black-color, 0.7);
            &:hover {
                color: rgba($black-color, 1);
            }
        }
        .breadcrumb-item {
            float: none;
            display: inline-block;
        }
    }
}
@media only screen and (max-width: 991px) {
    .page-title-wrapper {
        min-height: 70vh !important;
    }
    .page-title-wrapper-2 {
        min-height: 70vh !important;
    }
    .page-title-wrapper-3 {
        min-height: 70vh !important;
    }
    .page-title-wrapper-inner {
        padding-top: 50px;
        padding-bottom: 20px;
    }
}
.page-title-wrapper {
    display: table;
    width: 100%;
    height: 50vh;
    /* For at least Firefox */
    min-height: 100%;
    background-image: url('../images/img-1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    .page-title-wrapper-inner {
        display: table-cell;
        vertical-align: top;
        background: rgba(0,0,0,.25);;
    }

    .page-title-container {
        margin-right: auto;
        margin-left: auto;
        text-shadow: 0 .05rem .1rem rgba(0,0,0,.5);
        text-align: center;
        color: $white-color;
    }
    /* Padding for spacing */
    .inner {
        padding: 2rem;
    }
    /* Start the vertical centering */
    .page-title-wrapper-inner {
        vertical-align: middle;
    }
}

@media (min-width: 40em) {
    /* Handle the widths */
    .page-title-container {
        width: 100%;
    }
}
@media (min-width: 62em) {
    .page-title-container{
        width: 42rem;
    }
}


/*--------------------------------------------------------------
# 5. CTA
--------------------------------------------------------------*/
.cover-cta {
    margin-top: -50px;
    .form-control {
        width: 250px;
    }
    .form-inline {
        float: right;
    }
    .card {
        box-shadow: 0 2px 15px rgba(0,0,0,0.1);
        border-bottom: 3px solid $primary-color;
    }
}
@media only screen and (max-width: 991px) {
    .cover-cta {
        text-align: center;
        .form-inline {
            float: none;
            justify-content: center;
            .form-control {
                width: auto;
            }
        }
    }
}
.particles-cta-section {
    padding: 2rem 0;
    color: $white-color;
    position: relative;
    margin-top: -17px;
    .particles-content {
        z-index: 1;
        position: relative;
    }
    .particles-cta {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
}


/*--------------------------------------------------------------
# 6. PARTICLES
--------------------------------------------------------------*/
.particles-bg {
    height: 75vh;
}
.animated-cover-bg {
    .cover-wrapper {
        background: none;
        position: absolute;
        top: 140px;
        left: 0;
        width: 100%;
        height: 75vh;
    }
    .cover-wrapper-inner {
        background: none;
    }
}



/*--------------------------------------------------------------
# 7. CARDS
--------------------------------------------------------------*/
.news-card {
    box-shadow: 0 2px 15px rgba(0,0,0,0.1);
}
.card-img-overlay {
    background: $black-color;
    background: rgba($black-color, 0.6);
    background: linear-gradient(rgba($black-color, 0.8), rgba($black-color, 0));
    &.only-img {
        bottom: auto;
    }
}
.card-inverse {
    color: rgba(255, 255, 255, 0.75);
}
.card.info-on-hover {
    .card-img-overlay {
        opacity: 0;
        @include transition(all 0.3s ease-in-out);
    }
    &:hover {
        .card-img-overlay {
            opacity: 1;
        }
    }
}
.card-shadow {
    box-shadow: 0 2px 15px rgba(0,0,0,0.1);
}
.card-icon-block {
    position: relative;
}
.card-icon {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 2px solid $white-color;
    margin-top: -50px;
    margin-left: 25%;
    position: absolute;
}



/*--------------------------------------------------------------
# 8. SIDEBAR
--------------------------------------------------------------*/
.sidebar {
    .list-group-item {
        padding: 0;
        &.active {
            background-color: inherit;
            border: 0px;
            border-left: 3px solid $primary-color;
        }
        a {
            display: block;
            padding: 0.75rem 1.25rem;
            width: 100%;
            &:hover {
                background: $primary-color;
                color: $white-color;
            }
        }
    }
}




/*--------------------------------------------------------------
# 9. FOOTER
--------------------------------------------------------------*/
.st-footer {
    background-color: $black-color;
    padding-top: 4rem;
    padding-bottom: 2rem;
    color: rgba($white-color, .5);
    .column-title {
        color: rgba($white-color, .8);
    }
    .nav-link {
        color: rgba($white-color, .5);
        padding: 0.25rem 0;
        &:hover {
            color: rgba($white-color, .7);
        }
    }
}
.bg-footer {
    background: url("../images/pattern.png");
}
/*footer title for elements page*/
.title-separator-bar {
    border-bottom: 1px solid #fff;
    width: 30%;
    margin: 10px 0 15px;
}
/*--------------------------------------------------------------
# 10. BOXED & WIDE
--------------------------------------------------------------*/
.boxed {
    .main-wrapper-inner {
        box-shadow: 0 2px 15px rgba(0,0,0,0.1);
    }
}


/*--------------------------------------------------------------
# 11. MAP
--------------------------------------------------------------*/
.map-wrapper {
    .map {
        width: 100%;
        height: 400px;
        pointer-events: none;
        &.clicked {
            pointer-events: all;
        }
    }
}



/*--------------------------------------------------------------
# 12. TIMELINE
--------------------------------------------------------------*/
.timeline {
	list-style: none;
	padding: 20px 0;
	position: relative;
}
.timeline:before {
	top: 0;
	bottom: 0;
	position: absolute;
	content: " ";
	width: 3px;
	background-color: $gray-light;
	left: 50%;
	margin-left: -1.5px;
}
.timeline > li {
	margin-bottom: 20px;
	position: relative;
}
.timeline > li:after,
.timeline > li:before {
	content: " ";
	display: table;
}
.timeline > li:after {
	clear: both;
}
.timeline > li:after,
.timeline > li:before {
	content: " ";
	display: table;
}
.timeline > li:after {
	clear: both;
}
.timeline > li > .timeline-panel {
	width: 46%;
	float: left;
	position: relative;
}
.timeline > li > .timeline-panel:before {
	position: absolute;
	top: 26px;
	right: -15px;
	display: inline-block;
	border-top: 15px solid transparent;
	border-left: 15px solid #ccc;
	border-right: 0 solid #ccc;
	border-bottom: 15px solid transparent;
	content: " ";
}
.timeline > li > .timeline-panel:after {
	position: absolute;
	top: 27px;
	right: -14px;
	display: inline-block;
	border-top: 14px solid transparent;
	border-left: 14px solid #fff;
	border-right: 0 solid #fff;
	border-bottom: 14px solid transparent;
	content: " ";
}
.timeline > li > .timeline-badge {
	color: #fff;
	width: 50px;
	height: 50px;
	line-height: 50px;
	font-size: 1.4em;
	text-align: center;
	position: absolute;
	top: 16px;
	left: 50%;
	margin-left: -25px;
	background-color: $gray-light;
	z-index: 100;
	border-top-right-radius: 50%;
	border-top-left-radius: 50%;
	border-bottom-right-radius: 50%;
	border-bottom-left-radius: 50%;
}
.timeline > li.timeline-inverted > .timeline-panel {
	float: right;
}
.timeline > li.timeline-inverted > .timeline-panel:before {
	border-left-width: 0;
	border-right-width: 15px;
	left: -15px;
	right: auto;
}
.timeline > li.timeline-inverted > .timeline-panel:after {
	border-left-width: 0;
	border-right-width: 14px;
	left: -14px;
	right: auto;
}
.timeline-badge.primary {
	background-color: $primary-color !important;
}
.timeline-badge.success {
	background-color: $success-color !important;
}
.timeline-badge.warning {
	background-color: $warning-color !important;
}

.timeline-badge.danger {
	background-color: $danger-color !important;
}

.timeline-badge.info {
	background-color: $info-color !important;
}

.timeline-title {
	margin-top: 0;
	color: inherit;
}

.timeline-body > p,
.timeline-body > ul {
	margin-bottom: 0;
}

.timeline-body > p + p {
	margin-top: 5px;
}
@media (max-width: 767px) {
	ul.timeline:before {
		left: 40px;
	}

	ul.timeline > li > .timeline-panel {
		width: calc(100% - 90px);
		width: -moz-calc(100% - 90px);
		width: -webkit-calc(100% - 90px);
	}

	ul.timeline > li > .timeline-badge {
		left: 15px;
		margin-left: 0;
		top: 16px;
	}

	ul.timeline > li > .timeline-panel {
		float: right;
	}

	ul.timeline > li > .timeline-panel:before {
		border-left-width: 0;
		border-right-width: 15px;
		left: -15px;
		right: auto;
	}

	ul.timeline > li > .timeline-panel:after {
		border-left-width: 0;
		border-right-width: 14px;
		left: -14px;
		right: auto;
	}
}



/*--------------------------------------------------------------
# 13. SECTIONS
--------------------------------------------------------------*/
.content-section {
    padding: 4rem 0;
}
.white-section {
    background: $white-color;
}
.cards-section {
    margin-bottom: 2rem;
    margin-top: 3rem;
}
section.parallax {
    background-image: url('../images/img-1.jpg');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    .light-overlay, .dark-overlay, .primary-overlay {
        padding: 4rem 0;
    }
}
.light-overlay {
    background: rgba($white-color, 0.7);
}
.dark-overlay {
    background: rgba($black-color, 0.7);
}
.primary-overlay {
    background: rgba($primary-color, 0.7);
}
.bg-dark-grad-hor-1 {
    background: linear-gradient(to right, rgba(7,0,0,1) 1%,rgba(76,0,1,1) 50%,rgba(7,0,0,1) 100%) !important;
}
.bg-dark-grad-hor-2 {
    background: linear-gradient(to right, rgba(195,55,100,1) 1%,rgba(7,0,0,1) 100%) !important;
}
.bg-light-grad-ver-1 {
    background: linear-gradient(to bottom, rgba(189,223,247,1) 0%,rgba(249,238,224,1) 100%) !important;
}
.bg-dark-grad-rad-1 {
    background: #36D1DC;
    background: -webkit-linear-gradient(to right, #5B86E5, #36D1DC);
    background: linear-gradient(to right, #5B86E5, #36D1DC);
}
.bg-light-grad-hor-2 {
    background: linear-gradient(to right, rgba(122,255,255,1) 0%,rgba(63,255,105,1) 100%) !important;
}
.bg-img-2 {
    background-image: url('../images/img-2.jpg') !important;
}
.bg-img-3 {
    background-image: url('../images/img-3.jpg') !important;
}
.bg-img-4 {
    background-image: url('../images/img-4.jpg') !important;
}
.bg-img-5 {
    background-image: url('../images/img-5.jpg') !important;
}
.bg-img-6 {
    background-image: url('../images/img-6.jpg') !important;
}
.bg-img-7 {
    background-image: url('../images/img-7.jpg') !important;
}
.bg-img-testimonial {
    background-image: url('../images/sunset.jpg') !important;
}
.bg-parallax-1 {
    background-image: url(../images/parallax/water-lily-left.jpg)!important;
    background-repeat: no-repeat;
    background-size: cover;
}
.bg-img-8 {
    background-image: url('../images/calculator.jpg') !important;
}
@media only screen and (max-width: 576px) {
    .counter-boxes {
        text-align: center;
    }
}


/*--------------------------------------------------------------
# 14. ACCORDIONS
--------------------------------------------------------------*/
.accordion-wrap {
    .card {
        margin-bottom: 0.5rem;
        .card-header {
            padding: 0;
        }
        .card-header a {
            display: block;
            padding: 0.75rem 1.25rem;
            .icon {
                @include transition(all 0.4s ease);
            }
            &.collapsed {
                .icon {
                    transform: rotate(-90deg);
                }
            }
        }
    }
}



/*--------------------------------------------------------------
# 15. CAROUSEL
--------------------------------------------------------------*/
/* Carousel base class */
.carousel {
	margin-bottom: 1rem;
    box-shadow: 0 2px 15px rgba(0,0,0,0.1);
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
	z-index: 10;
	bottom: 3rem;
}
/* Declare heights because of positioning of img element */
.carousel-item {
	height: 450px;
	background-color: transparent;
}

.carousel-item > img {
	position: absolute;
	top: 0;
	left: 0;
	min-width: 100%;
	height: 450px;
}
@media (min-width: 40em) {
	/* Bump up size of carousel content */
	.carousel-caption p {
		margin-bottom: 1.25rem;
		font-size: 1.25rem;
		line-height: 1.4;
	}
}



/*--------------------------------------------------------------
# 16. OWL CAROUSEL
--------------------------------------------------------------*/
@import 'owl/owl.carousel';
$color-base: $primary-color;
$color-white: $white-color;
$color-gray: darken($gray-color, 10%);
@import 'owl/theme.default';

.owl-theme {
    .owl-nav {
        [class*='owl-'] {
            @include transition(all 0.4s ease-in-out);
        }
    }
}
.owl-carousel {
	position: relative;

	.owl-nav {
		position: absolute;
		top: 40%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 100%;
		.owl-prev {
			float: left;
            margin-left: -30px;
		}
		.owl-next {
			float: right;
            margin-right: -30px;
		}
	}
}

@media only screen and (max-width: 768px) {
    .owl-carousel .owl-nav .owl-prev {
        display: none !important;
    }
    .owl-carousel .owl-nav .owl-next {
        display: none !important;
    }
}
.testimonials-design {
    height: 150px;
    width: 150px !important;
    border-radius: 50%;
    display: inline-block !important;
    border: 5px inset rebeccapurple;
}

/*--------------------------------------------------------------
# 17. FEATURES
--------------------------------------------------------------*/
.feature-icon {
    font-size: 2rem;
}
.one-feature {
    padding-bottom: 1.5rem;
}



/*--------------------------------------------------------------
# 18. BLOG
--------------------------------------------------------------*/
.entry-header {
    margin-bottom: 15px;
    .entry-title {
        font-size: 1.5rem;
    }
    .entry-meta {
        color: $lighter-black-color;
        font-size: 0.8rem;
        a {
            color: $lighter-black-color;
        }
        span.meta-span {
            margin-right: 15px;
            .fa {
                margin-right: 5px;
            }
        }
    }
}
.post {
    margin-bottom: 30px;
    .st-post-thumb {
        position: relative;
        width: 100%;
        height: 300px;
        overflow: hidden;
        border-radius: 4px 4px 0 0;
        img.attachment-post-thumbnail {
            position: absolute;
            left: 50%;
            top: 50%;
            height: auto;
            transform: translate(-50%,-50%);
        }
    }
}
.col-md-6 {
    .post {
        .st-post-thumb {
            height: 200px;
        }
    }
}




/*--------------------------------------------------------------
# 19. MISCELLANEOUS
--------------------------------------------------------------*/
.breadcrumb {
    background: transparent;
    font-size: 0.85rem;
}
th, strong {
    font-weight: 500;
}
[data-toggle="popover"] {
    cursor: pointer;
}
.faq-div {
    margin-top: 2rem;
}
.btn-primary:focus {
    color: $white-color;
}
@media (max-width: 576px) {
    .btn {
        white-space: inherit;
    }
}
button, input, optgroup, select, textarea {
    font-family: $st-body-font;
}
// home page carousel
.banner-carousel .carousel-item > img {
    height: 70vh !important;
}
.banner-carousel .carousel-item {
    height: 70vh !important;
}
.banner-carousel .carousel-caption {
    bottom: 21vh !important;
}
.banner-carousel .dark-overlay {
    background: rgba(0, 0, 0, 0.4);
}

@media only screen and (max-width: 768px) {
    .banner-carousel .carousel-caption {
    bottom: 12vh !important;
    }
}
// END home page carousel
.scroll-top-button {
    position: fixed;
    bottom: -50px;
    right: 15px;
    background: $black-color;
    background: rgba($black-color, 0.5);
    padding: 10px 15px;
    color: $gray-color;
    border-radius: 0.25rem;
    cursor: pointer;
    @include transition(all 0.4s ease-in-out);
}
@media (max-width: 576px) {
    .scroll-top-button {
        padding: 5px 10px;
    }
}
/*blockquote*/
.blockquote {
    border-left: 0.25rem solid $primary-color;
}
.blockquote-reverse {
    border-right: 0.25rem solid  $primary-color;
    font-size: 1.25rem;
}

/*banner dotted image overlay*/
.banner-img {
    clear: both;
    padding: 0;
    position: relative;
    margin: 0 -1px;
    height: 60vh;
    overflow: hidden;
    background-color: #e5e5e5;
    .img_wrapper {
        position: relative;
        width: 100%;
        height: 60vh;
        box-shadow: 1px 1px 50px #4A4B45;
        -moz-box-shadow: 1px 1px 50px #4A4B45;
        -webkit-box-shadow: 1px 1px 50px #4A4B45;
        margin: 0 auto 0;
    }
    .post-image {
        margin: 0px 0px 0px 1px !important;
        display: block;
        height: 60vh;
        position: relative;
        width: 100%;
    }
    .banner-title {
        bottom: 30px;
        left: 18px;
        position: absolute;
        h2 {
            background: url("../images/background-pattern/blue-dot.png") repeat;
            padding: 10px 20px;
            color: #fcfcfc;
            float: left;
        }
    }
}
.bganner-h {
    position: absolute;
    left: 0;
    top: 0;
    background: url("../images/background-pattern/dot.png") repeat;
    width: 100%;
    height: 60vh;
    z-index: 100;
}
.banner-mid {
    margin: 0 auto;
    position: relative;
    top: -50px;
}
/* END banner dotted image overlay*/

/* Top contact-bar*/
.contact-bar {
    padding: 25px 0 5px 0;
    background: $white-color;
    .media {
        .fa {
            font-size: 1.5rem;
            margin-right: .875rem;
        }
        small {
            font-size: 0.75rem;
        }
    }

}
@media (max-width: 768px) {
    .logo-col {
        text-align: center;
    }
}
/* END Top contact-bar*/

.career-resume {
    background: #f2f2f2;
    padding: 20px;
    border-left: 5px solid $danger-color;
}

/*newsletter-box*/
.newsletter-box input {
    width: 50%;
    margin-bottom: 20px;
    margin: auto;
}
/*Home card image blur overlay*/
.img-over-text {
    max-width: 100%;
    min-height: 300px;
    position: relative;
    overflow: hidden;
    border-top-right-radius: calc(0.25rem - 1px);
    border-top-left-radius: calc(0.25rem - 1px);
    >div {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px 10px;
        background: inherit;
        background-attachment: fixed;
        overflow: hidden;
        &::before {
            content: "";
            position: absolute;
            top: -20px;
            left: 0;
            width: 200%;
            height: 200%;
            background: inherit;
            background-attachment: fixed;
            -webkit-filter: blur(4px);
            filter: blur(4px);
        }
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.25);
        }
        >h5 {
            margin: 0;
            color: white;
            position: relative;
            z-index: 1;
        }
    }
}
.bg-img-over-text-1 {
    background: url("../images/card/card-2.jpg");
    background-position: center;
}
.bg-img-over-text-2 {
    background: url("../images/card/card-1.jpg");
    background-position: center;
}
.bg-img-over-text-3 {
    background: url("../images/card/card-3.jpg");
    background-position: center;
}
/* END Home card image blur overlay*/

/*banner dotted image overlay*/
.banner-img {
    clear: both;
    padding: 0;
    position: relative;
    margin: 0 -1px;
    height: 60vh;
    overflow: hidden;
    background-color: #e5e5e5;
    .img_wrapper {
        position: relative;
        width: 100%;
        height: 60vh;
        box-shadow: 1px 1px 50px #4A4B45;
        -moz-box-shadow: 1px 1px 50px #4A4B45;
        -webkit-box-shadow: 1px 1px 50px #4A4B45;
        margin: 0 auto 0;
    }
    .post-image {
        margin: 0px 0px 0px 1px !important;
        display: block;
        height: 60vh;
        position: relative;
        width: 100%;
    }
    .banner-title {
        bottom: 30px;
        left: 18px;
        position: absolute;
        z-index: 999;
        h2 {
            background: url("../images/background-pattern/blue-dot.png") repeat;
            padding: 10px 20px;
            color: #fcfcfc;
            float: left;
        }
    }
}
.bganner-h {
    position: absolute;
    left: 0;
    top: 0;
    background: url("../images/background-pattern/diagonal line.png") repeat;
    width: 100%;
    height: 60vh;
    z-index: 100;
}
.bganner-h1 {
    position: absolute;
    left: 0;
    top: 0;
    background: url("../images/background-pattern/dot.png") repeat;
    width: 100%;
    height: 60vh;
    z-index: 100;
}
.banner-mid {
    margin: 0 auto;
    position: relative;
    top: -50px;
}
/* END banner dotted image overlay*/